import React,{ useCallback, useEffect,useState } from "react";
import AddContentTable from "./add-content.table";
import AddContentDrawer from "./add-content.drawer";
import ContentGlobalDrawer from "./content.global.drawer"

import {
  createQueryParams,
} from "utils/helpers/helper-functions";
import { contentRelations as relations } from "services/relations";
import {  useDispatch,useSelector } from "react-redux";
import * as FacilityAction from "redux/facility/Actions";


function AddContent() {
  const dispatch = useDispatch();
  const [drawer, setDrawer] = useState(false);
  const [globalDrawer, setGlobalDrawer] = useState(false);
  const [editGlobal, setEditGlobal] = useState(false);
  const [editRecord, setEditRecord] = useState(null);
  const [deleteRecord, setDeleteRecord] = useState(null);
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [view, setView] = useState(false);
  const [whereText, setWhereText] = useState("");

  const [country, setCountry] = useState("");
  const [countryName, setCountryName] = useState("");
  const [facility, setFacility] = useState("");
  const [doctor, setDoctor] = useState("");
  const [specility, setSpecility] = useState("");
  const globalRecord = useSelector((state) => state.facility.globalRecord);

  
  const getPagination = () => {
    const limit = pageSize;
    let offset = (pageNumber - 1) * pageSize;
    offset = offset > 1 ? offset : 0;
    const text = searchText.trim() || null;
    if (searchText.length>1){
      offset=0
    }
    const where=whereText;
    const query = createQueryParams(limit, offset, text, relations, where);
    return { limit, offset, text, relations, query };
  };
  const getAllPagination = () => {
    const limit=1000000;
    const offset=0;
    const text='';
    const relations = ["city"];
    const query = createQueryParams(limit, offset, text, relations);
    return { limit, offset, text, relations, query };
  };
  const getAllContent = () => {
    const limit = pageSize;
    let offset = (pageNumber - 1) * pageSize;
    offset = offset > 1 ? offset : 0;
    const text='';
    const relations = [];
    const query = createQueryParams(limit, offset, text, relations);
    return { limit, offset, text, relations, query };
  };
  const query = `limit=1000000&offset=0`;

  const fetchGlobalContent = useCallback(async () => {
  try {
    dispatch(FacilityAction.loading({ getGlobal: true }));
    await dispatch(FacilityAction.getGlobalContent("where[type]=facility")); 
  } catch (error) {
    console.error("Error fetching global content:", error);
  } finally {
    dispatch(FacilityAction.loading({ getGlobal: false }));
  }
}, [dispatch]);

  useEffect(() => {
    !globalDrawer && fetchGlobalContent();
  }, [fetchGlobalContent,globalDrawer]);
  

  useEffect(() => {
    setEditGlobal(globalRecord.count>0?true:false)
  }, [globalRecord]);

  const commonProps = {
    view,
    setView,
    pageSize,
    setPageSize,
    pageNumber,
    setPageNumber,
    searchText,
    setSearchText,
    getPagination,
    getAllPagination,
    drawer,
    setDrawer,
    deleteRecord,
    setDeleteRecord,
    editRecord,
    setEditRecord,
    query,
    whereText,
    setWhereText,
    getAllContent
    ,country,setCountry,countryName,setCountryName,facility,setFacility,doctor,setDoctor,specility,setSpecility,
    globalDrawer,
    setGlobalDrawer,
    editGlobal,
    setEditGlobal,
  };

  return (
    <div>
      <div className="py-4 mt-3 table_main_div d-flex justify-content-between">
        <h1 className="mx-5 mt-2"> Add Facility Content </h1>
        {globalRecord && globalRecord.count > 0 ? (
            <button
              onClick={() => setGlobalDrawer(true)}
              className="btn mx-2 btn-theme"
            >
              Edit Global Content
            </button>
          ) : (
            <button
              onClick={() => setGlobalDrawer(true)}
              className="btn mx-2 btn-theme"
            >
              Add Global Content
            </button>
          )}
      </div>

      <AddContentTable {...commonProps} />
      <AddContentDrawer {...commonProps} />
      <ContentGlobalDrawer {...commonProps} />
    </div>
  );
}

export default AddContent;
