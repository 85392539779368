import DraftContentTable from "./draft-content.table";
import ApproveDraftContentDrawer from "./approve-draft-content.drawer";
import {
  createQueryParams,
} from "utils/helpers/helper-functions";
import { useState } from "react";

function DraftContent() {
  const [drawer, setDrawer] = useState(false);
  const [editRecord, setEditRecord] = useState(null);
  const [rejectRecord, setRejectRecord] = useState(null);
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [view, setView] = useState(false);

  const getPagination = () => {
    const limit = pageSize;
    let offset = (pageNumber - 1) * pageSize;
    offset = offset > 1 ? offset : 0;
    const text = searchText.trim() || null;
    if (searchText.length>1){
      offset=0
    }
    const relations = [];
    const query = createQueryParams(limit, offset, text, relations);
    return { limit, offset, text, relations, query };
  };
  const getAllPagination = () => {
    const limit=1000000;
    const offset=0;
    const text='';
    const relations=['city'];
    const query = createQueryParams(limit, offset, text, relations);
    return { limit, offset, text, relations, query };
  };
  const query = `limit=1000000&offset=0`;


  const commonProps = {
    view,
    setView,
    pageSize,
    setPageSize,
    pageNumber,
    setPageNumber,
    searchText,
    setSearchText,
    getPagination,
    getAllPagination,
    drawer,
    setDrawer,
    setRejectRecord,
    rejectRecord,
    editRecord,
    setEditRecord,
    query
  };

  return (
    <div>
      <div className="py-4 mt-3 table_main_div d-flex justify-content-between">
        <h1 className="mx-5 mt-2"> Draft Doctor Content</h1>
      </div>

      <DraftContentTable {...commonProps} />
      <ApproveDraftContentDrawer {...commonProps} />
    </div>
  );
}

export default DraftContent;
