import { Drawer, Space, Spin } from "antd";
import { useFormik } from "formik";
import { useEffect, useState, useCallback } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "redux/request/Actions";
import * as userActions from "redux/user/Actions";
import * as Yup from "yup";
import Select from "react-select";

const RequestUpdateModal = ({ updateModal, ...props }) => {
  const { setUpdateModal, getPagination, editRecord, setEditRecord } = props;
  const [selectedAssignedOption, setSelectedAssignedOption] = useState(null);
  const user = useSelector((state) => state.auth.user);
  const { loading, userRecords } = useSelector(
    (state) => ({
      loading: state.request?.loading,
      userRecords: state.user?.records || [],
    }),
    shallowEqual
  );

  const fetch = useCallback(
    () => {
      Promise.all([dispatch(userActions.getAll(""))]);
    },
    // eslint-disable-next-line
    []
  );

  useEffect(
    () => {
      if (updateModal?.userId) {
        const user = userRecords.find(({ id }) => id === updateModal?.userId);
        formik.setFieldValue("userId",  user.id);
      }
      if(user.role==='admin'){
        fetch();
      }
    },
    // eslint-disable-next-line
    []
  );
 

  useEffect(() => {
    if (updateModal?.user?.email) {
      const foundUser = userRecords.find(
        ({ email }) => email === updateModal?.user?.email
      );
      if (foundUser) {
        setSelectedAssignedOption({
          value: foundUser.id,
          label: (
            <div>
              <span className="d-block h5 text-inherit mb-0">
                {foundUser.name}
              </span>
              <span className="d-block fs-5 text-body">{foundUser.email}</span>
            </div>
          ),
        });
      } 
    }
    else{
    setSelectedAssignedOption(null)
    }
  }, [updateModal, updateModal?.user?.email,userRecords]); // if error then removed this userRecords
  
  const optionsAssignedUser = userRecords?.map(({ id, name, email }) => ({
    value: id,
    label: (
      <div>
        <span className="d-block h5 text-inherit mb-0">{name}</span>
        <span className="d-block fs-5 text-body">{email}</span>
      </div>
    ),
  }));

  const handleUserAssignedChange = (selectedAssignedOption) => {
    setSelectedAssignedOption(selectedAssignedOption);
    if(selectedAssignedOption){
      formik.setFieldValue("userId", selectedAssignedOption.value);
    }
    else{
      formik.setFieldValue("userId", null);

    }
  };

  //formik Handler
  const initialValues = {
    userId: updateModal?.userId || null,
  };

  const validationSchema = Yup.object().shape({
    userId: Yup.string().nullable(),
    });

  const setEditDefaultValues = () => {
    formik.setValues({ ...initialValues });
  };

  const view = false;
  const isEditing = Boolean(updateModal);
  const isLoading = isEditing ? loading.update : loading.create;

  const title = " Edit Request";
  const dispatch = useDispatch();
  const pagination = getPagination();

  const closeDrawer = () => setUpdateModal(false);

  const onDrawerChange = (e) => {
    if (!e) {
      editRecord && setEditRecord(null);
      dispatch(
        actions.loading({ update: false, create: false, remove: false })
      );
    }
  };


  const onSubmit = async (values) => {
    dispatch(actions.loading({ update: true }));
    const { id } = updateModal;
    await dispatch(
      actions.update(id, { ...values }, setUpdateModal, pagination)
    );
  };

  const formik = useFormik({ initialValues, validationSchema, onSubmit });
  useEffect(
    () => {
      updateModal && isEditing && setEditDefaultValues();
    },
    // eslint-disable-next-line
    [updateModal]
  );

  return (
    <>
      <Drawer
        afterOpenChange={onDrawerChange}
        title={title}
        placement={"right"}
        width={"70%"}
        onClose={closeDrawer}
        open={Boolean(updateModal)}
        extra={
          <Space>
            {!view && (
              <>
                <button
                  type="button"
                  disabled={isLoading}
                  onClick={formik.handleSubmit}
                  className={`btn-sm btn ${
                    isLoading ? "btn-light" : "btn-theme"
                  } px-4`}
                >
                  {isLoading ? <Spin /> : "save"}
                </button>
              </>
            )}
          </Space>
        }
      >
        <>
          <div className="drawer_main_div">
            <h2 className=" text-center">{title}</h2>
            <div className="container-fluid">
              <form onSubmit={formik.handleSubmit}>
                <div className="row">
                  <div className="my-2">
                    <span className="drawer_span ">Assigned To</span>
                    <Select
                      isMulti={false}
                      className="drawer_input"
                      menuPosition={"fixed"}
                      placeholder={
                        updateModal?.user?.email
                          ? updateModal.user.email
                          : "Not Assigned"
                      }
                      styles={{
                        placeholder: (provided) => ({
                          ...provided,
                          fontSize: "13px",
                          color: "#677788",
                        }),
                        singleValue: (provided) => ({
                          ...provided,
                          fontSize: "13px",
                          color: "#677788",
                        }),
                      }}
                      name={"userId"}
                      value={selectedAssignedOption ? selectedAssignedOption : null }
                      options={optionsAssignedUser}
                      onChange={handleUserAssignedChange}
                      classNamePrefix="select"
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                   
                      filterOption={(option, inputValue) => 
                        // Custom filter logic for searching by email within the label
                        option.data.label.props.children[1].props.children
                          .toLowerCase()
                          .includes(inputValue.toLowerCase())
                      }
                  
                    />
                  </div>
                </div>
             
              </form>
            </div>
          </div>
        </>
      </Drawer>
    </>
  );
};

export default RequestUpdateModal;
