import ServicePriceDrawer from "app/dashboard/common/service-price/service-price.drawer";
import NoDataIcon from "components/animation-icons/no-data-icon";
import { LanguageSegment } from "components/language-segment/LanguageSegment";
import React,{useEffect} from "react";
import { shallowEqual, useSelector,useDispatch } from "react-redux";
import { createQueryParams } from "utils/helpers/helper-functions";
import { ServicePriceSelect } from "./common/service-price.select";
import * as servicePriceActions from "redux/common/service-price/Actions";
import TranslationNotAvailable from "components/translation-not-available/TranslationNotAvailable";

function Prices({ formik, view }) {
  const { Segment, language } = LanguageSegment();
  const [drawer, setDrawer] = React.useState(false);
  const dispatch = useDispatch();

  const getPagination = () => {
    const limit = 100000;
    let offset = 0;
    const text = "";
    const relations = [];
    const query = createQueryParams(limit, offset, text, relations);
    return { limit, offset, text, relations, query };
  };

  const { servicePrice } = useSelector(
    (state) => ({ servicePrice: state.servicePrice?.records }),
    shallowEqual
  );

    useEffect(() => {
      dispatch(servicePriceActions.getAll({"query":"limit=100000&offset=0"}));
    }, 
    // eslint-disable-next-line
    [dispatch]);
  return (
    <>
      <ServicePriceDrawer
        setDrawer={setDrawer}
        drawer={drawer}
        getPagination={getPagination}
      />
      <div className="language-segmented drawer_area_main_div mx-3 px-3">
        {Segment}
      </div>

      <div className="drawer_area_main_div mx-3 my-2 px-3">
        <span className="pb-2">Service Prices</span>
        <div className="d-flex">
          <ServicePriceSelect
            name={"servicePriceIds"}
            value={formik.values.servicePriceIds || []}
            onChange={(e) => formik.setFieldValue("servicePriceIds", e)}
          />
          <div className="col-sm-auto d-flex align-items-center mx-2">
            <span
              onClick={() => setDrawer(!drawer)}
              className="btn btn-primary btn-theme"
              data-bs-toggle="modal"
              data-bs-target="#newProjectModal"
            >
              <i className="bi-plus me-1"></i> Add
            </span>
          </div>
        </div>
      </div>
      {formik.values.servicePriceIds?.length ? (
        <>
          <div className="content container-fluid   px-3">
            <div className="row justify-content-lg-center ">
              <div className="col-lg-12">
                <div className="tab-content" id="profileTeamsTabContent">
                  <div
                    className="tab-pane fade active show"
                    id="list"
                    role="tabpanel"
                    aria-labelledby="list-tab"
                  >
                    <div className="row row-cols-1">
                    {formik.values.servicePriceIds.map((index, entityIndex) => {
                      const priceData =  servicePrice?.find(s => s.id === index.value)
                       return (
                       <div className="col mb-3">
                            <div className="card card-body">
                              <div className="row align-items-md-center">
                                <div className="col-9 col-md-4 col-lg-3 mb-2 mb-md-0">
                                  <h4>
                                    <span className="cursor-pointer" style={{ color: "rgba(113, 134, 157)" }}>
                                    {(priceData && priceData[`name_${language}`]) || <TranslationNotAvailable />}
                                    </span>
                                  </h4>

                                  <span
                                    className="badge bg-soft-secondary text-secondary p-2 cursor-pointer"
                                    href="#"
                                  >
                                    {priceData && priceData.type}

                                  </span>
                                </div>

                                <div className="col-3 col-md-auto order-md-last text-end">
                                  <div className="dropdown">
                                    <button
                                      disabled={view}
                                      type="button"
                                      className="btn btn-ghost-secondary btn-icon btn-sm rounded-circle"
                                      id="teamsListDropdown1"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                    >
                                      <i className="bi-three-dots-vertical"></i>
                                    </button>

                                    <div
                                      className="dropdown-menu dropdown-menu-sm dropdown-menu-end"
                                      aria-labelledby="teamsListDropdown1"
                                    >
                                      <div
                                        className="dropdown-item text-danger"
                                        onClick={() => {
                                          formik.setFieldValue(
                                            "servicePriceIds",
                                            formik.values.servicePriceIds.filter(
                                              (item, itemIndex) =>
                                                entityIndex !== itemIndex
                                            )
                                          );
                                        }}
                                      >
                                        Delete
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-sm mb-2 mb-sm-0 text-center">
                                  <p></p>
                                </div>

                                <div className="col-sm-auto">
                                ${priceData?.price || "N/A"}
                                </div>
                              </div>
                            </div>
                          </div>
                         );
                        })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="px-4 drawer_area_main_div mx-3 my-2">
            <NoDataIcon />
          </div>
        </>
      )}
    </>
  );
}

export default Prices;
