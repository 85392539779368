import { Drawer, Space, Spin, Upload, notification,Image } from "antd";
import ErrorMessage from "components/error-message/ErrorMessage";
import { LanguageSegment } from "components/language-segment/LanguageSegment";
import RichEditor from "components/rich-text-Editor/RichEditor";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "redux/common/facility-speciality/Actions";
import * as uploadActions from "redux/upload/Actions";
import { getBase64 } from "utils/helpers/helper-functions";
import * as Yup from "yup";
import Select from "react-select";
import { FALLBACK_IMAGE_URL } from "utils/constants/contants";
import * as specialitiesActions from "redux/specialities/Actions";

function FacilitySpecialityDrawer(props) {
  const { Segment, language } = LanguageSegment();

  const {
    setDrawer,
    drawer,
    getPagination,
    editRecord,
    setEditRecord,
    view,
    setView,
    onEdit,
    idOfFacility
  } = props;
  const {
    detail_en = editRecord?.detail_en || "",
    detail_ar = editRecord?.detail_ar || "",
    detail_de = editRecord?.detail_de || "",
    detail_ru = editRecord?.detail_ru || "",
    detail_uk = editRecord?.detail_uk || "",
    specialityId = editRecord?.specialityId || "" ,
    facilityId = idOfFacility?.values?.id || editRecord?.facilityId || "" ,
    id = editRecord?.specialityId || "",
    image =editRecord?.image || null,
  } = editRecord || {};

  const dispatch = useDispatch();
  const isEditing = Boolean(editRecord);
  const [uploadedImage, setImage] = useState(null);
  const [previewImage, setPreviewImage] = useState("");
  const [filteredRecords, setFilteredRecords] = useState(null);
  const [speciality_ids, setSpeciality_ids] = useState([]);
  const [speciality_idsEditing, setSpeciality_idsEditing] = useState([]);

  useEffect(() => {
    setPreviewImage(isEditing ? editRecord?.image : "");
  },
  // eslint-disable-next-line
  [editRecord]);

  const onChange = async (e) => {
    const file = e.file;
    setImage(e.file);
    file.preview = await getBase64(file.originFileObj);
    setPreviewImage(file.url || file.preview);
    formik.setFieldValue("image", file.url || file.preview);
  };
  const removeImage = () => {
    setImage(null);
    setPreviewImage(null);
    formik.setFieldValue("image", "");
  };

  useEffect(() => {
    drawer && dispatch(specialitiesActions.getAll({"query":"limit=1000000&offset=0"}));
    },
    [dispatch, drawer]);

  const { records, loading,facilitySpecialityRecords } = useSelector(
    (state) => ({
      records: state.speciality?.records || [],
      loading: state.speciality?.loading,
      facilitySpecialityRecords: state.facilitySpeciality?.facilitySpecialitiesRecords || []
    }),
    shallowEqual
  );

  
  useEffect(() => {

    if (facilitySpecialityRecords && records) {
      // Extract all specialityIds from facilitySpecialityRecords, ensuring no null values
      const specialityIdsSet = new Set(facilitySpecialityRecords?.map(record => record.specialityId));
      // Filter records to include only those records whose id is not in the specialityIds
      const filtered = records?.filter(record => !specialityIdsSet.has(record.id));      
      setFilteredRecords(filtered);
    }
  }, 
  // [facilitySpecialityRecords, records] // causing max depth exceed
  // eslint-disable-next-line
  [drawer,facilitySpecialityRecords]
);



  const isLoading = isEditing ? loading.update : loading.create;
  const pagination = getPagination();
  
  const title = `${
    view ? "View" : isEditing ? " Edit " : " Add "
  }  Facility Speciality `;

  const setEditDefaultValues = () => {
    formik.setValues({ ...initialValues });
  };

  const resetForm = () => {
    formik.resetForm({
      values: {
        detail_en:  "",
        detail_ar: "",
        detail_ru:  "",
        detail_de: "",
        detail_uk: "",
        specialityId:  null,
        facilityId:facilityId || editRecord?.facilityId || "",
        image: "",
      }
    });
  };
  const closeDrawer = () => setDrawer(false);

  const onDrawerChange = (e) => {
    if (!e) {
      view && setView(false);
      editRecord && setEditRecord(null);
      resetForm();
      dispatch(
        actions.loading({ update: false, create: false, remove: false })
      );
    }
  };

  useEffect(() => {
    if (drawer && isEditing) {
      setEditDefaultValues();
    } else if (drawer && !isEditing) {
      resetForm();
    }
  }, 
  // eslint-disable-next-line
  [drawer, isEditing]);


  
  //formik Handler
  const initialValues = {
    detail_en: detail_en || "",
    detail_ar: detail_ar || "",
    detail_ru: detail_ru || "",
    detail_de: detail_de || "",
    detail_uk: detail_uk || "",
    specialityId: specialityId || "",
    facilityId:facilityId || editRecord?.facilityId || "",
    image: image || "",
  };

  const validationSchema = Yup.object().shape({
    detail_en: Yup.string().required("Detail (English) is required"),
    detail_ar: Yup.string(),
    detail_de: Yup.string(),
    detail_ru: Yup.string(),
    detail_uk: Yup.string(),
    specialityId: Yup.string().required("Speciality is required"),
    facilityId:Yup.string().notRequired(),
    image: Yup.string().notRequired(),
  });

  const onSubmit = async (values) => {
    dispatch(actions.loading(isEditing ? { update: true } : { create: true }));
  
    // Handle image upload
    if (uploadedImage) {
      const uploadedImages = await dispatch(uploadActions.upload([uploadedImage]));
      if (!uploadedImages?.length) {
        notification.error({ description: "Image Upload Failed" });
        return;
      }
      values.image = uploadedImages[0] || "";
    }
  
    // Decide whether to create or update
    const action = isEditing
      ? actions.updateFacilitySpeciality(id, values, setDrawer, resetForm, pagination)
      : actions.createFacilitySpeciality(values, setDrawer, resetForm, pagination);
  
    if (isEditing) {
      onEdit(values, id);
    }
  
    return dispatch(action).then((response) => {
      if (response && response.specialityId) {
        const newSpeciality = {
          value: response.specialityId,
          label: '',
        };

        // Append the new speciality to the existing array
        idOfFacility.values.specialityIds = [
          ...idOfFacility.values.specialityIds,
          newSpeciality
        ];
        }
  
      return response; // Return the response if needed elsewhere
    }).catch((error) => {
      // Handle any errors here
      console.error("Action error:", error);
    });
  };
  

  const formik = useFormik({ initialValues, validationSchema, onSubmit });
  
  useEffect(() => {
    if(drawer){

    setSpeciality_ids(filteredRecords?.map(({ id: value, ...names }) => ({
      value,
      label: names[`name_${language}`],
    })));
    setSpeciality_idsEditing(facilitySpecialityRecords?.map((rec) => ({
      value: rec?.speciality?.id,
      label: rec?.speciality?.[`name_${language}`],
    })));
  }
  },
  [filteredRecords, language,facilitySpecialityRecords,drawer]);

  const var_specialityId = formik.values.specialityId && (
    isEditing
      ? speciality_idsEditing?.find(option => option.value === formik.values.specialityId)
      : speciality_ids?.find(option => option.value === formik.values.specialityId)
  );
  return (
    <>
      <Drawer
        afterOpenChange={onDrawerChange}
        title={title}
        placement={"right"}
        width="70%"
        onClose={closeDrawer}
        open={drawer}
        extra={
          <Space>
            {!view && (
              <>
                {!editRecord && (
                  <>
                    <button
                      disabled={isLoading}
                      onClick={resetForm}
                      className="btn-sm btn btn-light px-4 "
                      style={{ border: "1px solid grey" }}
                    >
                      Clear
                    </button>
                  </>
                )}
                <button
                  type="button"
                  disabled={isLoading}
                  onClick={formik.handleSubmit}
                  className={`btn-sm btn ${
                    isLoading ? "btn-light" : "btn-theme"
                  } px-4`}
                >
                  {isLoading ? <Spin /> : "save"}
                </button>
              </>
            )}
          </Space>
        }
      >
        <div className="drawer_main_div ">
          <h2 className=" text-center">{title}</h2>

          {/* language segment */}
          <div className="language-segmented   px-3 mb-3">{Segment}</div>

          <div className="content container-fluid mt-2 mx-0 px-3">
            {/********** Name *********/}

            <div className="col mx-3">
            {language === "en" && (
                <>
                  {" "}
                  <div className="my-3">
                    <span className="drawer_span required ">
                    Speciality Name{" "}
                      <span className="form-label-secondary">(English)</span>
                    </span>
                    <Select
                          isDisabled={isEditing}
                          name="specialityId"
                          value={formik.values.specialityId && (isEditing ? speciality_idsEditing?.find(option => option.value === formik.values.specialityId) : speciality_ids?.find(option => option.value === formik.values.specialityId) ) }                 
                          options={speciality_ids || ""}
                          className="drawer_input form-control w-100 basic-multi-select multiSelectBox"
                          classNamePrefix="select"
                          menuPlacement="bottom"
                          onChange={(selectedOptions) => {
                            formik.setFieldValue("specialityId", selectedOptions.value);
                          }}
                          />
                    {formik.errors.specialityId && formik.touched.specialityId && (
                      <ErrorMessage message={formik.errors.specialityId} />
                    )}
                  </div>
                </>
              )}
              {language === "ar" && (
                <>
                  {" "}
                  <div className="my-3">
                    <span className="drawer_span required ">
                    Speciality Name{" "}
                      <span className="form-label-secondary">(Arabic)</span>
                    </span>
                    <Select
                          isDisabled={isEditing}
                          name="specialityId"
                          value={formik.values.specialityId && (isEditing ? speciality_idsEditing?.find(option => option.value === formik.values.specialityId) : speciality_ids?.find(option => option.value === formik.values.specialityId) ) }                 
                          options={speciality_ids || ""}
                          className="drawer_input form-control w-100 basic-multi-select multiSelectBox"
                          classNamePrefix="select"
                          menuPlacement="bottom"
                          onChange={(selectedOptions) => {
                            formik.setFieldValue("specialityId", selectedOptions.value);
                          }}
                          />
                    {formik.errors.specialityId && formik.touched.specialityId && (
                      <ErrorMessage message={formik.errors.specialityId} />
                    )}
                  </div>
                </>
              )}
              {language === "de" && (
                <>
                  {" "}
                  <div className="my-3">
                    <span className="drawer_span required ">
                    Speciality Name{" "}
                      <span className="form-label-secondary">(German)</span>
                    </span>
                    <Select
                          isDisabled={isEditing}
                          name="specialityId"
                          value={formik.values.specialityId && (isEditing ? speciality_idsEditing?.find(option => option.value === formik.values.specialityId) : speciality_ids?.find(option => option.value === formik.values.specialityId) ) }                 
                          options={speciality_ids || ""}
                          className="drawer_input form-control w-100 basic-multi-select multiSelectBox"
                          classNamePrefix="select"
                          menuPlacement="bottom"
                          onChange={(selectedOptions) => {
                            formik.setFieldValue("specialityId", selectedOptions.value);
                          }}
                          />
                    {formik.errors.specialityId && formik.touched.specialityId && (
                      <ErrorMessage message={formik.errors.specialityId} />
                    )}
                  </div>
                </>
              )}
              {language === "ru" && (
                <>
                  {" "}
                  <div className="my-3">
                    <span className="drawer_span required ">
                    Speciality Name{" "}
                      <span className="form-label-secondary">(Russian)</span>
                    </span>
                    <Select
                          isDisabled={isEditing}
                          name="specialityId"
                          value={formik.values.specialityId && (isEditing ? speciality_idsEditing?.find(option => option.value === formik.values.specialityId) : speciality_ids?.find(option => option.value === formik.values.specialityId) ) }                 
                          options={speciality_ids || ""}
                          className="drawer_input form-control w-100 basic-multi-select multiSelectBox"
                          classNamePrefix="select"
                          menuPlacement="bottom"
                          onChange={(selectedOptions) => {
                            formik.setFieldValue("specialityId", selectedOptions.value);
                          }}
                          />
                    {formik.errors.specialityId && formik.touched.specialityId && (
                      <ErrorMessage message={formik.errors.specialityId} />
                    )}
                  </div>
                </>
              )}
               {language === "uk" && (
                <>
                  {" "}
                  <div className="my-3">
                    <span className="drawer_span required ">
                    Speciality Name{" "}
                      <span className="form-label-secondary">(Ukrainian)</span>
                    </span>
                    <Select
                          isDisabled={isEditing}
                          name="specialityId"
                          value={formik.values.specialityId && (isEditing ? speciality_idsEditing?.find(option => option.value === formik.values.specialityId) : speciality_ids?.find(option => option.value === formik.values.specialityId) ) }                 
                          options={speciality_ids || ""}
                          className="drawer_input form-control w-100 basic-multi-select multiSelectBox"
                          classNamePrefix="select"
                          menuPlacement="bottom"
                          onChange={(selectedOptions) => {
                            formik.setFieldValue("specialityId", selectedOptions.value);
                          }}
                          />
                    {formik.errors.specialityId && formik.touched.specialityId && (
                      <ErrorMessage message={formik.errors.specialityId} />
                    )}
                  </div>
                </>
              )}
            </div>

            {/********** Detail *********/}
            <div className="col mx-3">
              {language === "en" && (
                <>
                  {" "}
                  <div className="my-3">
                    <span className="drawer_span required ">
                      Detail{" "}
                      <span className="form-label-secondary">(English)</span>
                    </span>

                    <RichEditor                  
                      value={(formik.values.detail_en ??facilitySpecialityRecords?.find(record => record?.specialityId === var_specialityId?.value? var_specialityId?.value:var_specialityId )?.[`detail_ar`] ) || ""}
                      formik={formik}
                      setValue={(value) => formik.setFieldValue("detail_en", value)}
                    />
                    {formik.errors.detail_en && formik.touched.detail_en && (
                      <ErrorMessage message={formik.errors.detail_en} />
                    )}
                  </div>
                </>
              )}

              {/* Detail  */}
              {language === "ar" && (
                <>
                  {" "}
                  <div className="my-3">
                    <span className="drawer_span">
                      Detail{" "}
                      <span className="form-label-secondary">(Arabic)</span>
                    </span>
                    <RichEditor
                        value={
                          formik.values.detail_ar ??
                          facilitySpecialityRecords?.find(record => record?.specialityId === formik?.values?.specialityId)?.[`detail_ar`] ??
                          ''
                        }
                      formik={formik}
                      setValue={(value) =>
                        formik.setFieldValue("detail_ar", value)
                      }
                    />
                  </div>
                </>
              )}

              {/* Detail  */}
              {language === "de" && (
                <>
                  {" "}
                  <div className="my-3">
                    <span className="drawer_span">
                      Detail{" "}
                      <span className="form-label-secondary">(German)</span>
                    </span>
                    <RichEditor
                       value={
                        formik.values.detail_de ??
                        facilitySpecialityRecords?.find(record => record?.specialityId === formik?.values?.specialityId)?.[`detail_de`] ??
                        ''
                      }
                      formik={formik}
                      setValue={(value) =>
                        formik.setFieldValue("detail_de", value)
                      }
                    />
                  </div>
                </>
              )}

              {/* Detail  */}
              {language === "ru" && (
                <>
                  {" "}
                  <div className="my-3">
                    <span className="drawer_span">
                      Detail{" "}
                      <span className="form-label-secondary">(Russian)</span>
                    </span>
                    <RichEditor
                      value={
                        formik.values.detail_ru ??
                        facilitySpecialityRecords?.find(record => record?.specialityId === formik?.values?.specialityId)?.[`detail_ru`] ??
                        ''
                      }
                      formik={formik}
                      setValue={(value) =>
                        formik.setFieldValue("detail_ru", value)
                      }
                    />
                  </div>
                </>
              )}

                {/* Detail  */}
                {language === "uk" && (
                <>
                  {" "}
                  <div className="my-3">
                    <span className="drawer_span">
                      Detail{" "}
                      <span className="form-label-secondary">(Ukrainian)</span>
                    </span>
                    <RichEditor
                       value={
                        formik.values.detail_uk ??
                        facilitySpecialityRecords?.find(record => record?.specialityId === formik?.values?.specialityId)?.[`detail_uk`] ??
                        ''
                      }
                      formik={formik}
                      setValue={(value) =>
                        formik.setFieldValue("detail_uk", value)
                      }
                    />
                  </div>
                </>
              )}

            </div>
           
             <div className=" m-auto my-4 text-center w-50">
                  <Image
                    className="drawer_input"
                    preview={previewImage ? true : false}
                    width={150}
                    height={150}
                    src={previewImage || FALLBACK_IMAGE_URL}
                    fallback={FALLBACK_IMAGE_URL}
                  />

                  <div className="mb-4 mt-2 text-center ">
                    {!view && (
                      <>
                        {" "}
                        <Upload
                          showUploadList={false}
                          maxCount={1}
                          disabled={isLoading || view}
                          onChange={onChange}
                        >
                          <button
                            type="button"
                            disabled={isLoading || view}
                            className="btn-theme btn py-2 px-4"
                          >
                            Upload
                          </button>
                        </Upload>
                        <button
                          type="button"
                          style={{
                            background: previewImage ? "#f30301" : "grey",
                          }}
                          className="btn py-2 px-4 mx-2 text-white "
                          disabled={isLoading || view || !previewImage}
                          onClick={removeImage}
                        >
                          Delete
                        </button>
                      </>
                    )}
                  </div>
                </div>
          </div>
        </div>
      </Drawer>
    </>
  );
}

export default FacilitySpecialityDrawer;
