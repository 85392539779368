import React, { useState } from "react";
import { createQueryParams } from "utils/helpers/helper-functions";
import UsersDrawer from "./user.drawer";
import UsersTable from "./user.table";
import { userRelations as relations } from "services/relations";

function User() {
  const [drawer, setDrawer] = useState(false);
  const [editRecord, setEditRecord] = useState(null);
  const [deleteRecord, setDeleteRecord] = useState(null);
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [view, setView] = useState(false);
  const [orderBy, setOrderBy] = useState("");

  const getPagination = () => {
    const limit = pageSize;
    let offset = (pageNumber - 1) * pageSize;
    offset = offset > 1 ? offset : 0;
    const text = searchText.trim() || null;
    const query = createQueryParams(limit, offset, text, relations,orderBy);
    return { limit, offset, text, relations, query };
  };
  const commonProps = {
    view,
    setView,
    pageSize,
    setPageSize,
    pageNumber,
    setPageNumber,
    searchText,
    setSearchText,
    getPagination,
    drawer,
    setDrawer,
    deleteRecord,
    setDeleteRecord,
    editRecord,
    setEditRecord,
    orderBy,
    setOrderBy
  };

  return (
    <div>
      <div className="py-4 mt-3 table_main_div d-flex justify-content-between">
        <h1 className="mx-5 mt-2">Users</h1>
        <button className="btn mx-5 btn-theme" onClick={() => setDrawer(true)}>
          Add New User
        </button>
      </div>

      <UsersTable {...commonProps} />
      <UsersDrawer {...commonProps} />
    </div>
  );
}

export default User;
