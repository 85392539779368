import { shallowEqual, useSelector,useDispatch } from "react-redux";
import { Pagination, Table  } from "antd";
import { useCallback, useEffect,useState } from "react";
import * as actions from "redux/specialities/Actions";
import * as contentActions from "redux/content/Actions";

import {
  DeleteButton,
  EditButton} from "components/button/Buttons";

function SpecilitiesTabView({ user,drawer,setDrawer,setEditRecord,setDeleteRecord,country,facility,doctor,countryName,specility }) {
  const dispatch = useDispatch();
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  let offset = (pageNumber - 1) * pageSize;
  offset = offset > 1 ? offset : 0;
  const {
    relationSpecilitiesRecords,
    relationCount,
    loading,
  } = useSelector(
    (state) => ({
      relationSpecilitiesRecords: state.speciality?.relationSpecilitiesRecords || 0,
      relationCount: state.speciality?.relationCount || 0,
      loading: state.speciality?.loading.getAllRelated
    }),
    shallowEqual
  );
  let editQuery = "";

  const handleEditContent = (id,caseIs) => {
   
    if (country) {
      editQuery += `where[countryId]=${country}`;
    }
    
    if (id) {
      editQuery += `${editQuery ? '&' : ''}where[specialityId]=${id}`;
    }
    editQuery += `${editQuery ? "&" : ""}where[contentType]=doctor`;
    editQuery += `${editQuery ? '&' : ''}isReturnIds=true`;

      dispatch(contentActions.getEditContent({ query: editQuery }))
      .then((response) => {
        if(caseIs==="Edit"){
        setEditRecord(response); 
        setDrawer("Specilities"); 
        }
        else{
          setDeleteRecord(response)
        }
      })
      .catch((error) => {
        console.error("Error fetching country details:", error);
      });
    
    }
 
  let query = `select[name_en]=true&select[id]=true&select[createdAt]=true&limit=${pageSize}&offset=${offset}${( specility) ? `&where[id]=${specility}` :''}&relations[]=slug&select[slug][slug]=true`;

  const fetchContent = useCallback(
    () => {
      dispatch(actions.getAllRelated({ query: query }));

    },
    // eslint-disable-next-line
    [pageSize, pageNumber, dispatch,drawer,
      // country,facility,doctor
      ,specility]
  );


  useEffect(
    () => {
      fetchContent();
    },
    // eslint-disable-next-line
    [dispatch, pageSize, pageNumber,drawer,country,facility,doctor,specility]
  );

  useEffect(
    () => {
      if (pageNumber > 1 ) {
        setPageNumber(1);
      }
    },
    // eslint-disable-next-line
    [specility]
  );
  const columns = [
    {
      title: (
        <>
         Speciality<span className="form-label-secondary">(English)</span>
        </>
      ),
      dataIndex: "name_en",
      key: "name_en",
      render: (text_en, entity) => {
        return <>{entity?.name_en}</>;
      },
    },
    {
      title: "URL",
      dataIndex: "url",
      key: "url",
      render: (text_en, entity) => {
        // Construct the URL based on the given conditions
        let url = "";
          url = `${process.env.REACT_APP_BASE_URL_USER}en/doctors/${countryName ? countryName.toLowerCase() + '/' : ''}${entity.slug?.slug}`;
        return (
          <a href={url} target="_blank" rel="noopener noreferrer">
            {url}
          </a>
        );
      },
    },

    {
      title: "Actions",
      dataIndex: "name_de",
      key: "name_de",
      width: "150px",
      render: (name_en, entity) => {
        return (
          <>
            <div className="d-flex mx-auto ">
              {(user.role === "admin" ||
                user.role === "author" ||
                user.role === "editor") && (
                <span className="nav-link mx-1">
                
                      <EditButton
                  onClick={() => {handleEditContent(entity?.id,"Edit") }}
                />
                </span>
              )}
              {user.role === "admin" && (
                <span className="nav-link ">
                  {/* <DeleteButton onClick={() => setDeleteRecord(entity)} /> */}
                  <DeleteButton 
                    onClick={() => {handleEditContent(entity?.id,"Delete") }}
                   />
                </span>
              )}
            </div>
          </>
        );
      },
    },
  ];

  const paginationHandler = (current, pageSize) => {
    setPageSize(pageSize);
    setPageNumber(current);
  };

  return (
   <>
    <div className="domain_history table_main py-3 px-2">
          <Table
            className="table_tag"
            size="small"
            loading={loading}
            // scroll={{ y: "calc(100vh - 420px)" }}
            scroll={{ x: "calc(100vh - 420px)" }}
            pagination={false}
            dataSource={relationSpecilitiesRecords}
            columns={columns}
          />
          <div
            className="d-flex mt-3 mr-5 justify-content-end"
            style={{ marginRight: "20px" }}
          >
            <Pagination
              showSizeChanger
              onChange={paginationHandler}
              onShowSizeChange={paginationHandler}
              pageSize={pageSize}
              defaultCurrent={1}
              current={pageNumber} 
              total={relationCount}
            />
          </div>
        </div>
   </>
  );
}

export default SpecilitiesTabView;
