import { Pagination } from "antd";
import { useCallback, useRef, useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { DeleteConfirmationDialog } from "components/delete-confirmation-dialog/DeleteConfirmationDialog";
import { DeleteConfirmationDialogWithComment } from "components/delete-confirmation-dialog/DeleteWithCommentConfirmationDialog";
import * as actions from "redux/request/Actions";
import { FALLBACK_IMAGE_URL } from "utils/constants/contants";
import Tooltip from "app/dashboard/request/request.tooltip";
import {COUNTRIES_TRANSLATED_LIST} from "utils/common/common"
import { FindCountryCode } from "utils/helpers/helper-functions.js";
import ColumnsConfig from "./ColumnsConfig";
import Filters from "./filters";
import InnerTable from './request-inner-table';

function RequestsTable(props) {
  const {
    deleteRecord,
    setDeleteRecord,
    pageSize,
    setPageSize,
    pageNumber,
    setPageNumber,
    whereText,
    setWhereText,
    getPagination,
    updateModal,
    event,
    setEvent,
    eventCompleted,
    setEventCompleted,
    setUpdateModal,
    setCommentModal,
    setPaymentModal,
    deleteComment,
    setDeleteComment,
    setEditComment,
    isDeleted,
    setIsDeleted,
    orderBy,
    setOrderBy
  } = props;

  const {
    records,
    count,
    loading,
    userRecords,
    authState,
    paymentsData,
    commentsData,
  } = useSelector(
    (state) => ({
      records: state.request?.records || [],
      count: state.request?.count || 0,
      loading: state.request?.loading,
      userRecords: state.user?.records || [],
      authState: state.auth,
      paymentsData: state.request?.paymentsData || [],
      commentsData: state.request?.commentsData || [],
    }),
    shallowEqual
  );

  const dispatch = useDispatch();
  const pagination = getPagination();
  const user = authState?.user;
  //Custom select for status ref
  const selectRef = useRef(null);
  const selectRefDeleted = useRef(null);
  // Store the previous minute in a ref
  const previousMinuteRef = useRef(new Date().getMinutes());
  const [hoveredTooltipId, setHoveredTooltipId] = useState(null);
  const [requestfilterDate, setRequestfilterDate] = useState("");
  const [eventFilterDate, setEventFilterDate] = useState("");
  const [country, setCountry] = useState("");
  const [userId, setUserId] = useState("");
  const [userName, setUserName] = useState("");
  const [assignedTo, setAssignedTo] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDeletedDropdownOpen, setIsDeletedDropdownOpen] = useState(false);
  const [selectedAssignedOption, setSelectedAssignedOption] = useState(null);
  const [data, setData] = useState({});
  const [activeTab, setActiveTab] = useState(null); // Track the active tab
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });
  const [expandedRowKey, setExpandedRowKey] = useState(null);
  const handleExpand = (expanded, record) => {
    setExpandedRowKey(expanded ? record.key : null); // Allow only one expanded row
  };
  const handleMouseEnter = (e, entity) => {
    setData(entity);
    setHoveredTooltipId(entity.id);
    setTooltipPosition({ top: e.clientY - 15, left: e.clientX - 200 });
  };
  // eslint-disable-next-line
  let isHoveringTooltip = false;

  const handleTooltipEnter = () => {
    isHoveringTooltip = true;
  };

  const optionsAssignedUser = userRecords?.map(({ id, name, email }) => ({
    value: id,
    label: (
      <div>
        <span className="d-block h5 text-inherit mb-0">{name}</span>
        <span className="d-block fs-5 text-body">{email}</span>
      </div>
    ),
  }));

  const options = [
    { value: "open", label: "Open" },
    { value: "moved", label: "Moved" },
    { value: "completed", label: "Completed" },
  ];

  const handleUserAssignedChange = (selectedAssignedOption) => {
    setSelectedAssignedOption(selectedAssignedOption);
    if (selectedAssignedOption) {
      setAssignedTo(selectedAssignedOption.value);
    } else {
      setAssignedTo(null);
    }
  };

  const handleOptionChange = (value) => {
    setSelectedOptions((prev) =>
      prev.includes(value) ? prev.filter((v) => v !== value) : [...prev, value]
    );
  };

  const handleSelectDeleted = (e) => {
    if (e.target.checked) {
      setIsDeleted("isDeleted=true");
    } else {
      setIsDeleted("");
    }
  };

  const handleSelectAll = () => {
    setSelectedOptions(options?.map((option) => option.value));
  };

  const handleSelectNone = () => {
    setSelectedOptions([]);
  };

  const handleClickOutside = (event) => {
    if (selectRef.current && !selectRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };
  const handleClickOutsideDelete = (event) => {
    if (
      selectRefDeleted.current &&
      !selectRefDeleted.current.contains(event.target)
    ) {
      setIsDeletedDropdownOpen(false);
    }
  };

  useEffect(() => {
    if (isDeletedDropdownOpen) {
      document.addEventListener("click", handleClickOutsideDelete);
    } else {
      document.removeEventListener("click", handleClickOutsideDelete);
    }
    return () => {
      document.removeEventListener("click", handleClickOutsideDelete);
    };
  }, [isDeletedDropdownOpen]);

  useEffect(() => {
    if (isDropdownOpen) {
      document.addEventListener("click", handleClickOutside);
    } else {
      document.removeEventListener("click", handleClickOutside);
    }
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isDropdownOpen]);

  const openEventModal = (event, entityEvent) => {
    handleRowClickWithoutEvent(entityEvent);
    event.stopPropagation();
    setEvent(entityEvent);
  };

  const openAssignedToDrawer = (event, entityEvent) => {
    event.stopPropagation();
    setUpdateModal(entityEvent);
  };
  const openAddCommentDrawer = (event, requestId) => {
    event.stopPropagation();
    setCommentModal(requestId);
  };
  const openAddPaymentDrawer = (event, requestId) => {
    event.stopPropagation();
    setPaymentModal(requestId);
  };

  const fetch = useCallback(
    (refresh) => {
      dispatch(actions.getAll(pagination, refresh));
    },
    // eslint-disable-next-line
    [pageSize, pageNumber, whereText, dispatch, isDeleted,orderBy]
  );

  const countryOptions = COUNTRIES_TRANSLATED_LIST?.map(
    ({ id: value, name_en }, index) => ({
      value,
      label: name_en,
      index,
    })
  );
  const resetCountry = () => {
    setCountry(null);
  };
  const resetAssignedTo = () => {
    setAssignedTo(null);
    setSelectedAssignedOption(null);
  };
  useEffect(
    () => {
      if (eventCompleted === "done") {
        setEventCompleted(false);
        fetch(true);
      }
      if (event === "done") {
        setEvent(false);
        fetch(true);
      }
      if (updateModal === "done") {
        setUpdateModal(false);
        fetch(true);
      }
    },
    // eslint-disable-next-line
    [eventCompleted, event, updateModal]
  );

  useEffect(() => {
    fetch(true);

    const interval = setInterval(() => {
      const currentTime = new Date();
      const currentMinute = currentTime.getMinutes();

      // Store the previous minute in a ref
      if (currentMinute !== previousMinuteRef.current) {
        fetch(false);
        previousMinuteRef.current = currentMinute; // Update the previous minute
      }
    }, 1000); // Check every second

    return () => clearInterval(interval); // Cleanup interval on component unmount

    // eslint-disable-next-line
  }, [dispatch, pageSize, pageNumber, whereText, isDeleted,orderBy]);



  const handleDelete = (comment) => {
    const addComment = { text: comment, userId: user?.id,requestId: deleteRecord?.id};
    dispatch(actions.comment(addComment, setCommentModal, pagination, false));
    dispatch(actions.remove(deleteRecord?.id, setDeleteRecord, pagination));
  };

  const truncateMessage = (message, charLimit) => {
    if (message.length <= charLimit) {
      return message;
    }
    return message.slice(0, charLimit) + "...";
  };

  const handleDeleteComment = () => {
    dispatch(actions.removeComment(deleteComment?.id, setDeleteComment,deleteComment?.request?.id));
  };

  useEffect(() => {
    let queryStringParts = [];
    // Add userId
    if (userId) {
      queryStringParts.push(`where[uniqueId]=${userId}`);
    }

    // Add userName
    if (userName) {
      queryStringParts.push(`userName=${userName}`);
    }
    
    // Add request date
    if (requestfilterDate)
    {
      queryStringParts.push(`createdAt=${requestfilterDate}`);
    }

    // Add event date
    if (eventFilterDate) {
      queryStringParts.push(`eventCreatedAt=${eventFilterDate}`);
    }

    // Add country
    if (country) {
      queryStringParts.push(`country=${country}`);
    }

    // Assigned To
    if (assignedTo) {
      queryStringParts.push(`where[userId]=${assignedTo}`);
    }

    // Add status
    if (selectedOptions.length > 0) {
      selectedOptions.forEach((option) => {
        queryStringParts.push(`status[]=${option}`);
      });
    }
    // Combine all parts into a single query string
    const newQueryString =
      queryStringParts.length > 0 ? queryStringParts.join("&") : null;
    if (newQueryString) {
      setWhereText(newQueryString);
    } else {
      setWhereText(null);
    }
  }, [
    selectedOptions,
    requestfilterDate,
    eventFilterDate,
    country,
    userId,
    userName,
    setWhereText,
    assignedTo,
  ]);

  const rowClassName = (record) => {
    return record.isNew ? "row-isNew" : "";
  };

  const handleRowClickWithoutEvent = (record) => {
    if (record.isNew) {
      dispatch(actions.update(record?.id, { isNew: false }, pagination));
    }
  };

  const handleRowClick = (e, record) => {
    e.stopPropagation();
    if (record.isNew) {
      dispatch(actions.update(record?.id, { isNew: false }, pagination));
    }
  };

  const handleCommentTabClick = (id) => {
    try {
      setActiveTab(`comment-${id}`); // Set active tab
      dispatch(actions.getRequestComment({ query: `where[requestId]=${id}` }));
    } catch (error) {
      console.error("Error fetching comments:", error);
    }
  };

  const handleRequestPaymentTabClick = (id) => {
    try {
      setActiveTab(`payment-${id}`); // Set active tab
      dispatch(actions.getRequestPayment({ query: `where[requestId]=${id}` }));
    } catch (error) {
      console.error("Error fetching client requests:", error);
    }
  };

  const columns = ColumnsConfig({
      handleRowClick,
      handleRowClickWithoutEvent,
      FindCountryCode,
      FALLBACK_IMAGE_URL,
      user,
      openAssignedToDrawer,
      truncateMessage,
      handleTooltipEnter,
      handleMouseEnter,
      setEventCompleted,
      setDeleteRecord,
      isDeleted,  
      orderBy,
      setOrderBy
    });

  const paginationHandler = (current, pageSize) => {
    setPageSize(pageSize);
    setPageNumber(current);
  };

  return (
    <>
      <div
        className="pb-2 pt-3 table_main_div request-main-div"
        style={{ height: "contain" }}
      >
        <div className="row " style={{ paddingRight: "50px" }}>
          <div className="d-grid d-sm-flex gap-3 justify-content-between">
            <div className=" mx-4 ">
              <h3 className="  pt-3">Total - {count}</h3>
            </div>
          </div>
        </div>

        {/* Filter Component */}
        <Filters
          country={country}
          countryOptions={countryOptions}
          setCountry={setCountry}
          resetCountry={resetCountry}
          selectedAssignedOption={selectedAssignedOption}
          optionsAssignedUser={optionsAssignedUser}
          handleUserAssignedChange={handleUserAssignedChange}
          resetAssignedTo={resetAssignedTo}
          isDropdownOpen={isDropdownOpen}
          setIsDropdownOpen={setIsDropdownOpen}
          selectedOptions={selectedOptions}
          options={options}
          handleSelectAll={handleSelectAll}
          handleSelectNone={handleSelectNone}
          handleOptionChange={handleOptionChange}
          selectRef={selectRef}
          selectRefDeleted={selectRefDeleted}
          isDeleted={isDeleted}
          isDeletedDropdownOpen={isDeletedDropdownOpen}
          setIsDeletedDropdownOpen={setIsDeletedDropdownOpen}
          handleSelectDeleted={handleSelectDeleted}
          requestfilterDate={requestfilterDate}
          setRequestfilterDate={setRequestfilterDate}
          eventFilterDate={eventFilterDate}
          setEventFilterDate={setEventFilterDate}
          userId={userId}
          setUserId={setUserId}
          userName={userName}
          setUserName={setUserName}
        />
        <div className="domain_history table_main py-3 px-2">
       
       {/* Inner table component */}
       <InnerTable
        loading={loading}
        expandedRowKey={expandedRowKey}
        onExpand={handleExpand}
        records={records}
        columns={columns}
        rowClassName={rowClassName}
        activeTab={activeTab}
        handleCommentTabClick={handleCommentTabClick}
        handleRequestPaymentTabClick={handleRequestPaymentTabClick}
        openAddCommentDrawer={openAddCommentDrawer}
        openAddPaymentDrawer={openAddPaymentDrawer}
        setEditComment={setEditComment}
        setCommentModal={setCommentModal}
        setDeleteComment={setDeleteComment}
        commentsData={commentsData}
        paymentsData={paymentsData}
        setOrderBy={setOrderBy}
      />
          <Tooltip
            data={data}
            tooltipPosition={tooltipPosition}
            setData={setData}
            setHoveredTooltipId={setHoveredTooltipId}
            openEventModal={openEventModal}
            hoveredTooltipId={hoveredTooltipId}
          />

          <div
            className="d-flex mt-3 mr-5 justify-content-end"
            style={{ marginRight: "20px" }}
          >
            <Pagination
              showSizeChanger
              onChange={paginationHandler}
              onShowSizeChange={paginationHandler}
              pageSize={pageSize}
              defaultCurrent={1}
              total={count}
            />
          </div>
        </div>
      </div>

      <DeleteConfirmationDialogWithComment
        loading={loading?.remove}
        open={deleteRecord}
        onClose={setDeleteRecord}
        onDelete={handleDelete}
        title="Delete"
        content="Are you sure you want to delete it?"
        onCancelLabel="Cancel"
        onConfirmLabel="Delete"
      />
      <DeleteConfirmationDialog
        loading={loading?.remove}
        open={deleteComment}
        onClose={setDeleteComment}
        onDelete={handleDeleteComment}
        title="Delete"
        content="Are you sure you want to delete it?"
        onCancelLabel="Cancel"
        onConfirmLabel="Delete"
      />
    </>
  );
}

export default RequestsTable;
