import FilterContentTable from "./filter-content.table";
import FilterContentDrawer from "./filter-content.drawer";
import {
  createQueryParams,
} from "utils/helpers/helper-functions";
import { useState } from "react";
import { contentRelations as relations } from "services/relations";

function FilterContent() {
  const [drawer, setDrawer] = useState(false);
  const [editRecord, setEditRecord] = useState(null);
  const [deleteRecord, setDeleteRecord] = useState(null);
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [view, setView] = useState(false);
  const [whereText, setWhereText] = useState("");
  const [flag, setFlag] = useState(false);

  const getPagination = () => {
    const limit = pageSize;
    let offset = (pageNumber - 1) * pageSize;
    offset = offset > 1 ? offset : 0;
    const text = searchText.trim() || null;
    if (searchText.length>1){
      offset=0
    }
    const where=whereText;
    const query = createQueryParams(limit, offset, text, relations, where);
    return { limit, offset, text, relations, query };
  };
  const getAllPagination = () => {
    const limit=1000000;
    const offset=0;
    const text='';
    const relations = ["city"];
    const query = createQueryParams(limit, offset, text, relations);
    return { limit, offset, text, relations, query };
  };
  // const getAllContent = () => {
  //   const limit = pageSize;
  //   let offset = (pageNumber - 1) * pageSize;
  //   offset = offset > 1 ? offset : 0;
  //   const text='';
  //   const relations = ["content"];
  //   const query = createQueryParams(limit, offset, text, relations);
  //   return { limit, offset, text, relations, query };
  // };
  const query = `limit=1000000&offset=0`;


  const commonProps = {
    view,
    setView,
    pageSize,
    setPageSize,
    pageNumber,
    setPageNumber,
    searchText,
    setSearchText,
    getPagination,
    getAllPagination,
    drawer,
    setDrawer,
    deleteRecord,
    setDeleteRecord,
    editRecord,
    setEditRecord,
    query,
    flag,
    setFlag,
    setWhereText
  };

  return (
    <div>
      <div className="py-4 mt-3 table_main_div d-flex justify-content-between">
        <h1 className="mx-5 mt-2"> Filter Doctor Content </h1>
      </div>

      <FilterContentTable {...commonProps} />
      <FilterContentDrawer {...commonProps} />
    </div>
  );
}

export default FilterContent;
