import { Tabs } from "antd";
import {  useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { DeleteConfirmationDialog } from "components/delete-confirmation-dialog/DeleteConfirmationDialog";
import * as actions from "redux/content/Actions";
import * as specialitiesAction from "redux/specialities/Actions";
import * as CountryAction from "redux/country/Actions";
import * as facilityActions from "redux/facility/Actions";
import * as doctorActions from "redux/doctor/Actions";
import Select from "react-select";
import TableViewCountry from "./tabs-view/country-tab-view";
import DoctorTabView from "./tabs-view/doctor-tab-view";
import FacilityTabView from "./tabs-view/facility-tab-view";
import SpecilitiesTabView from "./tabs-view/specilities-tab-view";
function AddContentTable(props) {
  const {
    drawer,
    setDrawer,
    deleteRecord,
    setDeleteRecord,
    setEditRecord,
    getPagination,
    setWhereText,
    country,setCountry,countryName,setCountryName,facility,setFacility,doctor,setDoctor,specility,setSpecility
  } = props;

  const dispatch = useDispatch();
  const pagination = getPagination();
  const {
    user,
    loading,
    countryRecords,
    facilityRecords,
    doctorRecords,
    specialityRecords,
  } = useSelector(
    (state) => ({
      user: state.auth.user || null,
      count: state.content?.count || 0,
      loading: state.content?.loading,
      countryRecords: state.country?.records || [],
      facilityRecords: state.facility?.records || [],
      doctorRecords: state.doctor?.records || [],
      specialityRecords: state.speciality?.records,
    }),
    shallowEqual
  );

  const [countryConditon, setCountryConditon] = useState("");
  const [doctorCondition, setDoctorCondition] = useState("");
  const [specilityCondition, setSpecilityCondition] = useState("");
  const [selectedTab, setSelectedTab] = useState("1");


  useEffect(
    () => {
      let countryQuery = `select[name_en]=true&select[id]=true&select[createdAt]=true&limit=1000000&offset=0&relations[]=slug&select[slug][slug]=true`;
      let facilityQuery = `select[name_en]=true&select[id]=true&select[createdAt]=true&limit=1000000&offset=0${(country) ? `&where[countryId]=${country}`:''}`;
      let doctorQuery = `select[firstName_en]=true&select[lastName_en]=true&select[id]=true&select[createdAt]=true&limit=1000000&offset=0${!facility && country ? `&where[countryId]=${country}`: ""}${facility ? `&where[facilityDoctor][facilityId]=${facility}` : ""}`;
      let specilityQuery = `select[name_en]=true&select[id]=true&select[createdAt]=true&limit=1000000&offset=0`;

      dispatch(CountryAction.getAll({ query: countryQuery }));
      dispatch(facilityActions.getAll({ query: facilityQuery }));
      dispatch(doctorActions.getAll({ query: doctorQuery }));
      dispatch(specialitiesAction.getAll({ query: specilityQuery }));
      
    },
    // eslint-disable-next-line
    [
      countryConditon,
      doctorCondition,
      specilityCondition,
    ]
  );


  
  useEffect(
    () => {
      facilityRecords && facilityRecords?.length===0 && setFacility(null);
      doctorRecords && doctorRecords?.length===0 && setDoctor(null);
      specialityRecords && specialityRecords?.length===0 && setSpecility(null);
    },
    // eslint-disable-next-line
    [facilityRecords,doctorRecords,specialityRecords]
  );

  const countryOptions = countryRecords?.map(
    ({ id: value,name_en, slug }, index) => ({
      value,
      slug: slug?.slug,
      label:name_en,
      index,
    })
  );

  const facilityOptions = facilityRecords?.map(
    ({ id: value, name_en }, index) => ({
      value,
      label: name_en,
      index,
    })
  );
  const doctorOptions = doctorRecords?.map(
    ({ id: value, firstName_en }, index) => ({
      value,
      label: firstName_en,
      index,
    })
  );
  const specialitiesOptions = specialityRecords?.map(
    ({ id: value, name_en }, index) => ({
      value,
      label: name_en,
      index,
    })
  );

  const handleDelete = () => {
      dispatch(actions.remove(deleteRecord?.id, setDeleteRecord, pagination));
  };

  useEffect(() => {
    let queryStringParts = [];

    if (country) {
      queryStringParts.push(`where[countryId]=${country}`);
      setCountryConditon(`&where[countryId]=${country}`);
      
    }
    if (facility || country) {
      facility && queryStringParts.push(`where[facilityId]=${facility}`);
    
      if (!facility && country) {
        setDoctorCondition(`&where[countryId]=${country}`);
      } else if (facility && !country) {
        setDoctorCondition(`&where[facilityDoctor][facilityId]=${facility}`);
      } else if (facility && country) {
        setDoctorCondition(`&where[countryId]=${country}&where[facilityDoctor][facilityId]=${facility}`);
      }
    }
    

    if (doctor) {
      queryStringParts.push(`where[doctorId]=${doctor}`);
    }
    if (specility) {
      queryStringParts.push(`where[specialityId]=${specility}`);
    }

    // Combine all parts into a single query string
    const newQueryString =
      queryStringParts.length > 0 ? queryStringParts.join("&") : null;
    setWhereText(newQueryString ? newQueryString : null);
  }, [country, facility, doctor, specility, setWhereText]);

  const resetCountry = () => {
    setCountryName(null)
    setCountry(null);
    setCountryConditon('');
    setDoctorCondition('');
  };
  const resetFacility = () => {
    setFacility(null);
    setDoctorCondition('');
    setSpecilityCondition('');
  };
  const resetDoctor = () => {
    setDoctor(null);
    setDoctorCondition('');
  };
  const resetSpecility = () => {
    setSpecility(null);
  };

  const items = [
    {
      key: "1",
      label: `Country (${country? 1:(countryRecords?.length !== undefined ? countryRecords.length : 0)})`,
      disabled: false,
      children: (
        <TableViewCountry
          {...{
            user,
            drawer,
            setDrawer,
            setEditRecord,
            setDeleteRecord,
            country,
            facility,
            doctor,
            specility,
          }}
        />
      ),
    },
    {
      key: "2",
      label: `Hospitals/Clinics (${facility? 1:(facilityRecords?.length !== undefined ? facilityRecords.length : 0)})`,
      
      children: (
        <FacilityTabView
          {...{
            user,
            drawer,
            setDrawer,
            setEditRecord,
            setDeleteRecord,
            country,
            facility,
            doctor,
            specility,
            selectedTab,
          }}
        />
      ),
    },
    {
      key: "3",
      label: `Doctor (${doctor? 1:(doctorRecords?.length !== undefined ? doctorRecords.length : 0)})`,
      
      children: (
        <DoctorTabView
          {...{
            user,
            drawer,
            setDrawer,
            setEditRecord,
            setDeleteRecord,
            country,
            facility,
            doctor,
            specility
          }}
        />
      ),
    },
    {
      key: "4",
      label: `Specilities (${specility? 1: (specialityRecords?.length !== undefined ? specialityRecords.length : 0)})`,
      children: (
        <SpecilitiesTabView
          {...{
            user,
            drawer,
            setDrawer,
            setEditRecord,
            setDeleteRecord,
            country,
            facility,
            doctor,
            countryName,
            specility,
          }}
        />
      ),
    },
  ];

  return (
    <>
      <div className="pb-2 pt-3 table_main_div " style={{ height: "contain" }}>
        <div className="row mx-2 " style={{ paddingRight: "50px" }}>
          <div className="col-12 col-md-auto">
            <span className="drawer_span">Country</span>
            <div
              className="custom-select-container-content"
              style={{ display: "flex", alignItems: "center" }}
            >
              <Select
                isMulti={false}
                name="countries"
                value={
                  countryOptions?.find((option) => option?.value === country) ||
                  null
                }
                options={countryOptions}
                onChange={(selectedOption) => {
                  setCountry(selectedOption ? selectedOption.value : null);
                  setCountryName(selectedOption ? selectedOption.slug : null);
                }}
                className="drawer_input form-control w-100 basic-multi-select multiSelectBox"
                classNamePrefix="select"
              />
              <b
                onClick={resetCountry}
                style={{ marginLeft: "10px", cursor: "pointer" }}
              >
                <i class="bi bi-x-circle-fill"></i>
              </b>
            </div>
          </div>

          <div className="col-12 col-md-auto">
            <span className="drawer_span">Hospitals/Clinics</span>
            <div
              className="custom-select-container-content"
              style={{ display: "flex", alignItems: "center" }}
            >
              <Select
                isMulti={false}
                name="facilities"
                value={
                  facilityOptions?.find(
                    (option) => option?.value === facility
                  ) || null
                }
                options={facilityOptions}
                onChange={(selectedOption) => {
                  setFacility(selectedOption ? selectedOption.value : null);
                }}
                className="drawer_input form-control w-100 basic-multi-select multiSelectBox"
                classNamePrefix="select"
              />
              <b
                onClick={resetFacility}
                style={{ marginLeft: "10px", cursor: "pointer" }}
              >
                <i class="bi bi-x-circle-fill"></i>
              </b>
            </div>
          </div>

          <div className="col-12 col-md-auto">
            <span className="drawer_span">Doctor</span>
            <div
              className="custom-select-container-content"
              style={{ display: "flex", alignItems: "center" }}
            >
              <Select
                isMulti={false}
                name="doctors"
                value={
                  doctorOptions?.find((option) => option?.value === doctor) ||
                  null
                }
                options={doctorOptions}
                onChange={(selectedOption) => {
                  setDoctor(selectedOption ? selectedOption.value : null);
                }}
                className="drawer_input form-control w-100 basic-multi-select multiSelectBox"
                classNamePrefix="select"
              />
              <b
                onClick={resetDoctor}
                style={{ marginLeft: "10px", cursor: "pointer" }}
              >
                <i class="bi bi-x-circle-fill"></i>
              </b>
            </div>
          </div>

          <div className="col-12 col-md-auto">
            <span className="drawer_span">Specilities</span>
            <div
              className="custom-select-container-content"
              style={{ display: "flex", alignItems: "center" }}
            >
              <Select
                isMulti={false}
                name="Specilities"
                value={
                  specialitiesOptions?.find(
                    (option) => option?.value === specility
                  ) || null
                }
                options={specialitiesOptions}
                onChange={(selectedOption) => {
                  setSpecility(selectedOption ? selectedOption.value : null);
                }}
                className="drawer_input form-control w-100 basic-multi-select multiSelectBox"
                classNamePrefix="select"
              />
              <b
                onClick={resetSpecility}
                style={{ marginLeft: "10px", cursor: "pointer" }}
              >
                <i class="bi bi-x-circle-fill"></i>
              </b>
            </div>
          </div>
        </div>

        <div className="bg-white mx-3 mt-5">
          <Tabs
            defaultActiveKey="1"
            items={items}
            activeKey={selectedTab}
            onChange={(key) => setSelectedTab(key)}
          />
        </div>
      </div>

      <DeleteConfirmationDialog
        loading={loading?.remove}
        open={deleteRecord}
        onClose={setDeleteRecord}
        onDelete={handleDelete}
        title="Delete"
        content="Are you sure you want to delete it?"
        onCancelLabel="Cancel"
        onConfirmLabel="Delete"
      />
    </>
  );
}

export default AddContentTable;
