import { Pagination, Table } from "antd";
import { useCallback, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  DeleteButton,
  EditButton,
  ViewButton,
} from "components/button/Buttons";
import { DeleteConfirmationDialog } from "components/delete-confirmation-dialog/DeleteConfirmationDialog";
import * as actions from "redux/blog/Actions";
import { formatForDisplay } from "utils/helpers/helper-functions";

function BlogTable(props) {
  const {
    setView,
    setDrawer,
    deleteRecord,
    setDeleteRecord,
    setEditRecord,
    pageSize,
    setPageSize,
    pageNumber,
    setPageNumber,
    searchText,
    setSearchText,
    getPagination,
    orderBy,
    setOrderBy
  } = props;

  const dispatch = useDispatch();
  const pagination = getPagination();

  const { records, count, loading } = useSelector(
    (state) => ({
      records: state.blog?.records || [],
      count: state.blog?.count || 0,
      loading: state.blog?.loading,
    }),
    shallowEqual
  );

  const fetch = useCallback(() => {
    dispatch(actions.getAll(pagination));
  }, 
  // eslint-disable-next-line
  [pageSize, pageNumber, searchText, dispatch,orderBy]);

  useEffect(() => {
    fetch();
  }, 
  // eslint-disable-next-line
  [dispatch, pageSize, pageNumber, searchText,orderBy]);

  const handleDelete = () => {
    dispatch(actions.remove(deleteRecord?.id, setDeleteRecord, pagination));
  };

  const columns = [
    {
      title: "S.No",
      dataIndex: "name_ar",
      key: "name_ar",
      width: "100px",
      render: (name_en, entity, index) => {
        let S_No = index + ((pageNumber - 1) * pageSize + 1);
        return <>{S_No}</>;
      },
    },
    {
      title: "Title (English)",
      dataIndex: "title_en",
      key: "title_en",
      sorter: true, // Enables sorting (handled via onChange)

    },
    {
      title: "Title (Arabic)",
      dataIndex: "title_ar",
      key: "title_ar",
    },
    {
      title: "Title (Russian)",
      dataIndex: "title_ru",
      key: "title_ru",
    },
    {
      title: "Title (German)",
      dataIndex: "title_de",
      key: "title_de",
    },
    {
      title: "Title (Ukrainian)",
      dataIndex: "title_uk",
      key: "title_uk",
    },
    {
      title: "Created at",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt) => formatForDisplay(createdAt),
      sorter: true, // Enables sorting (handled via onChange)

    },
    {
      title: "Actions",
      dataIndex: "name_de",
      key: "name_de",
      width: "150px",
      render: (name_en, entity) => {
        return (
          <>
            <div className="d-flex mx-auto ">
              <span className="nav-link">
                <ViewButton
                  onClick={() => {
                    setView(true);
                    setEditRecord(entity);
                    setDrawer(true);
                  }}
                />
              </span>

              <span className="nav-link mx-1">
                <EditButton
                  onClick={() => {
                    setEditRecord(entity);
                    setDrawer(true);
                  }}
                />
              </span>
              <span className="nav-link ">
                <DeleteButton onClick={() => setDeleteRecord(entity)} />
              </span>
            </div>
          </>
        );
      },
    },
  ];

  const paginationHandler = (current, pageSize) => {
    setPageSize(pageSize);
    setPageNumber(current);
  };

  return (
    <>
      <div className="pb-2 pt-3 table_main_div " style={{ height: "contain" }}>
        <div className="row " style={{ paddingRight: "50px" }}>
          <div className="d-grid d-sm-flex gap-3 justify-content-between">
            <div className=" mx-4 ">
              <h3 className="  pt-3">Total - {count}</h3>
            </div>
            <div
              className="input-group input-group-merge input-group-flush mr-4 ml-2 "
              style={{ width: "180px" }}
            >
              <div className="input-group-prepend input-group-text">
                <i className="bi-search"></i>
              </div>
              <input
                onChange={(e) => setSearchText(e.target.value)}
                id="datatableSearch"
                type="search"
                className="form-control"
                placeholder="Search "
                aria-label="Search "
              />
            </div>
            {/* <!-- End Search --> */}
          </div>
        </div>

        <div className="domain_history table_main py-3 px-2">
          <Table
            className="table_tag"
            size="small"
            loading={loading?.getAll || false}
            // scroll={{ y: "calc(100vh - 420px)" }}
            scroll={{ x: 'max-content' }}

            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {},
              };
            }}
            pagination={false}
            dataSource={records}
            columns={columns}
            onChange={(pagination, filters, sorter) => {
              if (sorter.order) {
                setOrderBy(`order[${sorter.field}]=${sorter.order === "ascend" ? "ASC" : "DESC"}`);
              } else {
                setOrderBy(''); // Reset sorting when clicking to clear sort
              }
            }}
          />
          <div
            className="d-flex mt-3 mr-5 justify-content-end"
            style={{ marginRight: "20px" }}
          >
            <Pagination
              showSizeChanger
              onChange={paginationHandler}
              onShowSizeChange={paginationHandler}
              pageSize={pageSize}
              defaultCurrent={1}
              total={count}
            />
          </div>
        </div>
      </div>

      <DeleteConfirmationDialog
        loading={loading?.remove}
        open={deleteRecord}
        onClose={setDeleteRecord}
        onDelete={handleDelete}
        title="Delete"
        content="Are you sure you want to delete it?"
        onCancelLabel="Cancel"
        onConfirmLabel="Delete"
      />
    </>
  );
}

export default BlogTable;
