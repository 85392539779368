import { Select, Table } from "antd";
import { shallowEqual, useSelector } from "react-redux";

let width = "200px";
let selectWidth = "400px";

function FacilityBulk(props) {
  const { formik } = props;

  const {
    country,
    service,
    city,
    serviceLanguage,
    facilityDepartment,
    facilitySpeciality,
    facilityWhyChooseUs,
    faqs,
    doctor,
    servicePrice,
  } = useSelector(
    (state) => ({
      country: state.country?.records,
      service: state.service?.records,
      city: state.city?.records,
      serviceLanguage: state.serviceLanguage?.records,
      facilityDepartment: state.facilityDepartment?.records,
      facilitySpeciality: state.facilitySpeciality?.records,
      facilityWhyChooseUs: state.whyChooseUs?.records,
      faqs: state.faqs?.records,
      doctor: state.doctor?.records,
      servicePrice: state.servicePrice?.records,
    }),
    shallowEqual
  );

  const countries = country?.map(({ name_en, id, city = [] }) => {
    let children = city.map(({ name_en: label, id: value }) => ({
      value,
      label,
    }));
    return {
      value: id,
      label: name_en,
      children,
      disabled: children.length ? false : true,
    };
  });

  const services = service?.map(({ id: value, name_en }, index) => ({
    value,
    index,
    label: name_en,
  }));

  const serviceLanguages = serviceLanguage?.map(
    ({ id: value, name_en }, index) => ({ value, index, label: name_en })
  );

  const facilityDepartments = facilityDepartment?.map(
    ({ id: value, speciality }, index) => ({
      value,
      label: speciality?.name_en,
      index,
    })
  );

  const cities = city?.map(({ id: value, name_en, countryId }, index) => ({
    value,
    index,
    label: name_en,
    countryId,
  }));

  const facilitySpecialityDepartments = facilitySpeciality?.map(
    ({ id: value, name_en }, index) => ({ value, label: name_en, index })
  );

  const whyChooseUs = facilityWhyChooseUs?.map(
    ({ id: value, text_en }, index) => ({ value, index, label: text_en })
  );

  const faq = faqs?.map((entity, index) => ({
    value: entity?.id,
    label: entity?.question_en,
    index,
  }));

  const doctors = doctor?.map((doc, index) => ({
    value: doc?.id,
    label: (
      <>
        <span className="h5">{doc?.firstName_en + " " + doc.lastName_en}</span>
        <br />
        <span className="text-body text-right">{doc?.email}</span>
      </>
    ),
    index,
  }));
  const servicePrices = servicePrice?.map((entity, index) => ({
    value: entity?.id,
    label: (
      <>
        <p style={{ color: "rgba(113, 134, 157)" }}>
          {entity?.name_en} ($ {entity?.price})
        </p>

        <span className="badge bg-soft-secondary text-secondary p-2">
          {entity?.type}
        </span>
      </>
    ),
    index,
  }));

  const columns = [
    {
      fixed: "left",
      width: "60px",
      render: (_, entity, index) => (
        <>
          {formik.errors?.facility?.[index] ? (
            <>
              {Object.keys(formik.errors?.facility?.[index]).length > 0 && (
                <>
                  <div className="dropdown">
                    <button
                      style={{ height: "2rem", width: "2rem" }}
                      type="button"
                      className="btn btn-icon btn-danger rounded-circle"
                      id="navbarAppsDropdown"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      data-bs-dropdown-animation=""
                    >
                      <i className="bi-bug-fill"></i>
                      <span className="avatar-status avatar-md-status avatar-status-danger">
                        {Object.keys(formik.errors?.facility?.[index]).length}
                      </span>
                    </button>
                  </div>
                </>
              )}
            </>
          ) : (
            <>
              <span className="  text-success ms-2 h2">
                <i className="bi bi-check-circle-fill"></i>
              </span>
            </>
          )}
        </>
      ),
    },
    {
      title: <span className="required">Name</span>,
      width,
      render: (_, __, index) => (
        <TableInput {...{ name: "name_en", formik, index }} />
      ),
    },
    {
      title: <span className="required">Type</span>,
      width,
      render: (_, __, index) => (
        <TableSelect
          {...{
            isMulti: false,
            name: "type",
            formik,
            index,
            options: [
              { value: "clinic", index: 0, label: "Clinic" },
              { value: "hospital", index: 1, label: "Hospital" },
            ],
          }}
        />
      ),
    },
    {
      title: <span className="required">Address</span>,
      width,
      render: (_, __, index) => (
        <TableInput {...{ name: "address_en", formik, index }} />
      ),
    },
    {
      title: <span className="required">Country</span>,
      width: selectWidth,
      render: (_, __, index) => (
        <TableSelect
          {...{
            isMulti: false,
            name: "countryId",
            formik,
            index,
            options: countries,
          }}
        />
      ),
    },
    {
      title: <span className="required">City</span>,
      width: selectWidth,
      render: (_, __, index) => (
        <TableSelect
          {...{
            isMulti: false,
            name: "cityId",
            formik,
            index,
            options: cities.filter(
              ({ countryId }) =>
                countryId === formik.values?.facility?.[index]?.countryId
            ),
          }}
        />
      ),
    },
    {
      title: <span className="required">Zip Code</span>,
      width,
      render: (_, __, index) => (
        <TableInput {...{ name: "zipCode", formik, index }} />
      ),
    },
    {
      title: <span className="required">Email</span>,
      width,
      render: (_, __, index) => (
        <TableInput {...{ name: "email", formik, index }} />
      ),
    },
    {
      title: <span className="required">Phone</span>,
      width,
      render: (_, __, index) => (
        <TableInput {...{ name: "phone", formik, index }} />
      ),
    },
    {
      title: <span className="required">Year of foundation</span>,
      width,
      render: (_, __, index) => (
        <TableInput {...{ name: "foundation", formik, index }} />
      ),
    },
    {
      title: <span className="required">No of departments</span>,
      width,
      render: (_, __, index) => (
        <TableInput {...{ name: "department", formik, index }} />
      ),
    },
    {
      title: <span className="required">No of beds </span>,
      width,
      render: (_, __, index) => (
        <TableInput {...{ name: "bed", formik, index }} />
      ),
    },
    {
      title: <span className="required">No of doctors</span>,
      width,
      render: (_, __, index) => (
        <TableInput {...{ name: "doctor", formik, index }} />
      ),
    },
    {
      title: <span className="required">No of yearly patients</span>,
      width,
      render: (_, __, index) => (
        <TableInput {...{ name: "yearly_patient", formik, index }} />
      ),
    },
    {
      title: <span className="required">Overview</span>,
      width,
      render: (_, __, index) => (
        <TableInput {...{ name: "overview_en", formik, index }} />
      ),
    },
    {
      title: <>Services</>,
      width: selectWidth,
      render: (_, __, index) => (
        <TableSelect
          {...{ name: "serviceIds", formik, index, options: services }}
        />
      ),
    },
    {
      title: <>Service Languages</>,
      width: selectWidth,
      render: (_, __, index) => (
        <TableSelect
          {...{
            name: "serviceLanguageIds",
            formik,
            index,
            options: serviceLanguages,
          }}
        />
      ),
    },
    {
      title: <> Departments</>,
      width: selectWidth,
      render: (_, __, index) => (
        <TableSelect
          {...{
            name: "departmentIds",
            formik,
            index,
            options: facilityDepartments,
          }}
        />
      ),
    },
    {
      title: <>Specialities</>,
      width: selectWidth,
      render: (_, __, index) => (
        <TableSelect
          {...{
            name: "specialityIds",
            formik,
            index,
            options: facilitySpecialityDepartments,
          }}
        />
      ),
    },
    {
      title: <>WhyChooseUs</>,
      width: selectWidth,
      render: (_, __, index) => (
        <TableSelect
          {...{
            name: "whyChooseUsIds",
            formik,
            index,
            options: whyChooseUs,
          }}
        />
      ),
    },
    {
      title: <>Doctors</>,
      width: selectWidth,
      render: (_, __, index) => (
        // <TableDoctorSelect {...{ name: "doctorIds", formik, index }} />
        <TableSelect
          {...{
            name: "doctorIds",
            formik,
            index,
            options: doctors,
          }}
        />
      ),
    },
    {
      title: <>Service Prices</>,
      width: selectWidth,
      render: (_, __, index) => (
        // <TableServicePriceSelect
        //   {...{ name: "servicePriceIds", formik, index }}
        // />
        <TableSelect
          {...{
            name: "servicePriceIds",
            formik,
            index,
            options: servicePrices,
          }}
        />
      ),
    },
    {
      title: <>Faq</>,
      width: selectWidth,
      render: (_, __, index) => (
        <TableSelect {...{ name: "faqIds", formik, index, options: faq }} />
        // <TableFaqSelect {...{ name: "faqIds", formik, index }} />
      ),
    },
    {
      fixed: "right",
      width: "60px",
      render: (_, entity, index) => (
        <>
          <button
            onClick={() => {
              let value = formik.values.facility.filter((_, i) => i !== index);
              formik.setFieldValue("facility", value);
            }}
            className="btn btn-white text-danger btn-icon btn-sm show"
          >
            <i className="bi-trash"></i>
          </button>
        </>
      ),
    },
  ];

  const AddRecord = () => {
    formik.setFieldValue("facility", [...formik.values.facility, record]);
  };

  return (
    <div>
      <button className="btn btn-theme mb-3" onClick={AddRecord}>
        Add Row
      </button>
      <div className="bulk-facility-main-div">
        <Table
          bordered
          size="small"
          // scroll={{ y: "40vh" }}
          scroll={{ x: 1500, y: 500 }}
          pagination={false}
          dataSource={formik?.values.facility || []}
          columns={columns}
        />
      </div>
    </div>
  );
}

export default FacilityBulk;

let basicKeys = [
  "name_en",
  "address_en",
  "cityId",
  "countryId",
  "zipCode",
  "type",
  "email",
  "phone",
  "foundation",
  "department",
  "bed",
  "doctor",
  "yearly_patient",
  "overview_en",
  "lat",
  "long",
  "description_en",
  "overview_en",
];

let relationKeys = [
  "serviceLanguageIds",
  "servicePriceIds",
  "whyChooseUsIds",
  "departmentIds",
  "specialityIds",
  "serviceIds",
  "doctorIds",
  "faqIds",
];

var record = {
  ...Object.fromEntries(basicKeys.map((key) => [key, ""])),
  ...Object.fromEntries(relationKeys.map((key) => [key, []])),
  ...Object.fromEntries(["isActive", "isFeatured"].map((key) => [key, true])),
};

const TableInput = ({ formik, name = "", index }) => {
  return (
    <input
      name
      type="text"
      className={`form-control drawer_input ${
        formik.errors?.facility?.[index]?.[name] && "input_error"
      } `}
      onChange={(e) =>
        formik.setFieldValue(`facility[${index}].[${name}]`, e.target.value)
      }
      value={formik.values?.facility?.[index]?.[name] || ""}
    />
  );
};

const TableSelect = ({
  formik,
  name = "",
  index,
  options = [],
  isMulti = true,
  maxTagCount = 1,
}) => {
  return (
    <>
      <Select
        maxTagTextLength={10}
        maxTagCount={maxTagCount}
        value={formik.values?.facility?.[index]?.[name] || []}
        mode={isMulti ? "multiple" : ""}
        style={{ width: "100%" }}
        onChange={(value) => {
          formik.setFieldValue(`facility[${index}].[${name}]`, value);
        }}
        options={options}
      />
    </>
  );
};
