import { Drawer,  Space, Spin } from "antd";
import { LanguageSegment } from "components/language-segment/LanguageSegment";
import RichEditor from "components/rich-text-Editor/RichEditor";
import { useFormik } from "formik";
import { useEffect,useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "redux/doctor/Actions";
import * as Yup from "yup";
import { SaveConfirmationDialog } from "components/save-confirmation-dialog/SaveConfirmationDialog";
import ErrorMessage from "components/error-message/ErrorMessage";

function ContentGlobalDrawer(props) {
  const { view, setView, globalDrawer, setGlobalDrawer,editGlobal,setEditGlobal} =
    props;
  const globalRecord = useSelector((state) => state.doctor.globalRecord);
  const record = editGlobal || view;
  const { Segment, language } = LanguageSegment();
  const { loading } = useSelector(
    (state) => ({
      loading: state.doctor?.loading,
    }),
    shallowEqual
  );
  const {
    metaTitle_en =globalRecord.count>0?globalRecord?.records?.[0].metaTitle_en:"",
    metaTitle_ar =globalRecord.count>0?globalRecord?.records?.[0].metaTitle_ar:"",
    metaTitle_ru =globalRecord.count>0?globalRecord?.records?.[0].metaTitle_ru:"",
    metaTitle_de =globalRecord.count>0?globalRecord?.records?.[0].metaTitle_de:"",
    metaTitle_uk =globalRecord.count>0?globalRecord?.records?.[0].metaTitle_uk:"",
    metaKeyword_en =globalRecord.count>0?globalRecord?.records?.[0].metaKeyword_en:"",
    metaKeyword_ar =globalRecord.count>0?globalRecord?.records?.[0].metaKeyword_ar:"",
    metaKeyword_ru =globalRecord.count>0?globalRecord?.records?.[0].metaKeyword_ru:"",
    metaKeyword_de =globalRecord.count>0?globalRecord?.records?.[0].metaKeyword_de:"",
    metaKeyword_uk =globalRecord.count>0?globalRecord?.records?.[0].metaKeyword_uk:"",
    metaDescription_en =globalRecord.count>0?globalRecord?.records?.[0].metaDescription_en:"",
    metaDescription_ar =globalRecord.count>0?globalRecord?.records?.[0].metaDescription_ar:"",
    metaDescription_de =globalRecord.count>0?globalRecord?.records?.[0].metaDescription_de:"",
    metaDescription_ru =globalRecord.count>0?globalRecord?.records?.[0].metaDescription_ru:"",
    metaDescription_uk =globalRecord.count>0?globalRecord?.records?.[0].metaDescription_uk:"",
    overview_en = globalRecord.count>0?globalRecord?.records?.[0].overview_en:"",
    overview_ar = globalRecord.count>0?globalRecord?.records?.[0].overview_ar:"",
    overview_de = globalRecord.count>0?globalRecord?.records?.[0].overview_de:"",
    overview_ru = globalRecord.count>0?globalRecord?.records?.[0].overview_ru:"",
    overview_uk = globalRecord.count>0?globalRecord?.records?.[0].overview_uk:"",

    id = globalRecord.count>0?globalRecord.records[0].id:null,
  } = record || {};

  const isEditing = Boolean(record);
  const isLoading = editGlobal ? loading.update : loading.create;
  const title = `${ editGlobal ? " Edit " : " Add "} Global Content`;
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const initialValues = {
    metaTitle_en: metaTitle_en || "",
    metaTitle_ar: metaTitle_ar || "",
    metaTitle_ru: metaTitle_ru || "",
    metaTitle_de: metaTitle_de || "",
    metaTitle_uk: metaTitle_uk || "",
    metaKeyword_en: metaKeyword_en || "",
    metaKeyword_ar: metaKeyword_ar || "",
    metaKeyword_ru: metaKeyword_ru || "",
    metaKeyword_de: metaKeyword_de || "",
    metaKeyword_uk: metaKeyword_uk || "",
    metaDescription_en: metaDescription_en || "",
    metaDescription_ar: metaDescription_ar || "",
    metaDescription_ru: metaDescription_ru || "",
    metaDescription_de: metaDescription_de || "",
    metaDescription_uk: metaDescription_uk || "",
    overview_en: overview_en || "",
    overview_ar: overview_ar || "",
    overview_ru: overview_ru || "",
    overview_de: overview_de || "",
    overview_uk: overview_uk || "",
    type : "doctor",
  };

  const setEditDefaultValues = () => {
    formik.setValues({ ...initialValues });
  };

  const resetForm = () => {
    formik.resetForm();
  };

  const closeDrawer = () => setGlobalDrawer(false);
  const onDrawerChange = (e) => {
    if (!e) {
      setView(null);
      setEditGlobal(null);
      resetForm();
      dispatch(
        actions.loading({ update: false, create: false, remove: false })
      );
    }
  };

  useEffect(() => {
    globalDrawer && isEditing && setEditDefaultValues();
    setEditDefaultValues()
  },
  // eslint-disable-next-line
  [globalDrawer]);

   const validationSchema = Yup.object().shape({
     metaTitle_en: Yup.string().required("Meta Title (English) is required"),
     metaTitle_ar: Yup.string(),
     metaTitle_ru: Yup.string(),
     metaTitle_de: Yup.string(),
     metaTitle_uk: Yup.string(),
     metaKeyword_en: Yup.string(),
     metaKeyword_ar: Yup.string(),
     metaKeyword_ru: Yup.string(),
     metaKeyword_de: Yup.string(),
     metaKeyword_uk: Yup.string(),
     metaDescription_en: Yup.string().required("Description (English) is required"),
     metaDescription_ar: Yup.string(),
     metaDescription_ru: Yup.string(),
     metaDescription_de: Yup.string(),
     metaDescription_uk: Yup.string(),
     overview_en: Yup.string().required("Overview (English) is required"),
     overview_ar: Yup.string(),
     overview_ru: Yup.string(),
     overview_de: Yup.string(),
     overview_uk: Yup.string(),
   });
 
  const onSubmit = async (values) => {
    dispatch(actions.loading(editGlobal ? { update: true } : { create: true }));
    if (editGlobal) {
      values.id = id; 
    }
    const action =actions.createGlobal( values, setGlobalDrawer, resetForm)
    setOpen(false)
    return dispatch(action);
  };

  const formik = useFormik({ initialValues, validationSchema, onSubmit });

  return (
    <>
      <Drawer
        afterOpenChange={onDrawerChange}
        title={title}
        placement={"right"}
        width={"70%"}
        onClose={closeDrawer}
        open={globalDrawer}
        extra={
          <Space>
            {!view && (
              <>
                {!editGlobal && (
                  <>
                    <button
                      disabled={isLoading}
                      onClick={resetForm}
                      className="btn-sm btn btn-light px-4 "
                      style={{ border: "1px solid grey" }}
                    >
                      Clear 
                    </button>
                  </>
                )}
                <button
                  type="button"
                  disabled={isLoading}
                  onClick={async () => {
                    const errors = await formik.validateForm(); // Trigger validation
                    if (Object.keys(errors).length === 0) { // Check if there are no validation errors
                      setOpen(true); // Open confirmation dialog if form is valid
                    } else {
                      formik.setTouched(
                        Object.keys(formik.values).reduce((acc, field) => ({ ...acc, [field]: true }), {})
                      ); // Mark all fields as touched
                    }
                  }}
                  className={`btn-sm btn ${
                    isLoading ? "btn-light" : "btn-theme"
                  } px-4`}
                >
                  {isLoading ? <Spin /> : "save"}
                </button>
              </>
            )}
          </Space>
        }
      >
        <div
          className="language-segmented px-3 mb-2 "
          style={{
            padding: "20px 0",
            margin: 0,
            background: "#fff",
            border: "1px solid #dbdbdb",
            boxShadow: "0px 4px 31px rgba(0, 0, 0, 0.14)",
            borderRadius: "10px",
          }}
        >
          {Segment}
        </div>
        <div className="drawer_main_div ">
          <h2 className=" text-center ">{title}</h2>
          <div className="container-fluid">
            <div className="row">
              <form onSubmit={formik.handleSubmit}>
                {/* Detail  */}
                {language === "en" && (
                                <>
                                  {" "}
                                  <div className="my-2">
                                    <span className="drawer_span required">
                                      Meta Title{" "}
                                      <span className="form-label-secondary">(English)</span>
                                    </span>
                                    <input
                                      disabled={view}
                                      type="text"
                                      name="metaTitle_en"
                                      className={`form-control ${
                                        formik.errors.metaTitle_en &&
                                        formik.touched.metaTitle_en
                                          ? "input_error"
                                          : ""
                                      }`}
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                      value={formik.values.metaTitle_en}
                                      placeholder="Enter Meta Title in (English)"
                                    />
                                    {formik.errors.metaTitle_en &&
                                      formik.touched.metaTitle_en && (
                                        <ErrorMessage message={formik.errors.metaTitle_en} />
                                      )}
                                  </div>
                                  <div className="my-2">
                                    <span className="drawer_span">
                                      Meta Keyword{" "}
                                      <span className="form-label-secondary">(English)</span>
                                    </span>
                                    <input
                                      disabled={view}
                                      type="text"
                                      name="metaKeyword_en"
                                      className={`form-control ${
                                        formik.errors.metaKeyword_en &&
                                        formik.touched.metaKeyword_en
                                          ? "input_error"
                                          : ""
                                      }`}
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                      value={formik.values.metaKeyword_en}
                                      placeholder="Enter Meta Keyword in (English)"
                                    />
                                    {formik.errors.metaKeyword_en &&
                                      formik.touched.metaKeyword_en && (
                                        <ErrorMessage message={formik.errors.metaKeyword_en} />
                                      )}
                                  </div>
                                  <div className="my-3">
                                    <span className="drawer_span required">
                                      Meta Description{" "}
                                      <span className="form-label-secondary">(English)</span>
                                    </span>
                
                                    <RichEditor
                                      disabled={view}
                                      value={formik.values.metaDescription_en}
                                      formik={formik}
                                      setValue={(value) =>
                                        formik.setFieldValue("metaDescription_en", value)
                                      }
                                    />
                                    {formik.errors.metaDescription_en &&
                                      formik.touched.metaDescription_en && (
                                        <ErrorMessage
                                          message={formik.errors.metaDescription_en}
                                        />
                                      )}
                                  </div>
                                  <div className="my-3">
                                    <span className="drawer_span required">
                                      Overview{" "}
                                      <span className="form-label-secondary">(English)</span>
                                    </span>
                
                                    <RichEditor
                                      disabled={view}
                                      value={formik.values.overview_en}
                                      formik={formik}
                                      setValue={(value) =>
                                        formik.setFieldValue("overview_en", value)
                                      }
                                    />
                                    {formik.errors.overview_en &&
                                      formik.touched.overview_en && (
                                        <ErrorMessage message={formik.errors.overview_en} />
                                      )}
                                  </div>
                                </>
                              )}
                
                              {language === "ar" && (
                                <>
                                  {" "}
                                  <div className="my-2">
                                    <span className="drawer_span">
                                      Meta Title{" "}
                                      <span className="form-label-secondary">(Arabic)</span>
                                    </span>
                                    <input
                                      disabled={view}
                                      type="text"
                                      name="metaTitle_ar"
                                      className={`form-control ${
                                        formik.errors.metaTitle_ar &&
                                        formik.touched.metaTitle_ar
                                          ? "input_error"
                                          : ""
                                      }`}
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                      value={formik.values.metaTitle_ar}
                                      placeholder="Enter Meta Title in (Arabic)"
                                    />
                                    {formik.errors.metaTitle_ar &&
                                      formik.touched.metaTitle_ar && (
                                        <ErrorMessage message={formik.errors.metaTitle_ar} />
                                      )}
                                  </div>
                                  <div className="my-2">
                                    <span className="drawer_span ">
                                      Meta Keyword{" "}
                                      <span className="form-label-secondary">(Arabic)</span>
                                    </span>
                                    <input
                                      disabled={view}
                                      type="text"
                                      name="metaKeyword_ar"
                                      className={`form-control ${
                                        formik.errors.metaKeyword_ar &&
                                        formik.touched.metaKeyword_ar
                                          ? "input_error"
                                          : ""
                                      }`}
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                      value={formik.values.metaKeyword_ar}
                                      placeholder="Enter Meta Keyword in (Arabic)"
                                    />
                                    {formik.errors.metaKeyword_ar &&
                                      formik.touched.metaKeyword_ar && (
                                        <ErrorMessage message={formik.errors.metaKeyword_ar} />
                                      )}
                                  </div>
                                  <div className="my-3">
                                    <span className="drawer_span">
                                      Meta Description{" "}
                                      <span className="form-label-secondary">(Arabic)</span>
                                    </span>
                                    <RichEditor
                                      value={formik.values.metaDescription_ar}
                                      formik={formik}
                                      setValue={(value) =>
                                        formik.setFieldValue("metaDescription_ar", value)
                                      }
                                    />
                                  </div>
                                  <div className="my-3">
                                    <span className="drawer_span ">
                                      Overview{" "}
                                      <span className="form-label-secondary">(Arabic)</span>
                                    </span>
                
                                    <RichEditor
                                      value={formik.values.overview_ar}
                                      formik={formik}
                                      setValue={(value) =>
                                        formik.setFieldValue("overview_ar", value)
                                      }
                                    />
                                    {formik.errors.overview_ar &&
                                      formik.touched.overview_ar && (
                                        <ErrorMessage message={formik.errors.overview_ar} />
                                      )}
                                  </div>
                                </>
                              )}
                
                              {/* Detail  */}
                              {language === "de" && (
                                <>
                                  {" "}
                                  <div className="my-2">
                                    <span className="drawer_span">
                                      Meta Title{" "}
                                      <span className="form-label-secondary">(German)</span>
                                    </span>
                                    <input
                                      disabled={view}
                                      type="text"
                                      name="metaTitle_de"
                                      className={`form-control ${
                                        formik.errors.metaTitle_de &&
                                        formik.touched.metaTitle_de
                                          ? "input_error"
                                          : ""
                                      }`}
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                      value={formik.values.metaTitle_de}
                                      placeholder="Enter Meta Title in (German)"
                                    />
                                    {formik.errors.metaTitle_de &&
                                      formik.touched.metaTitle_de && (
                                        <ErrorMessage message={formik.errors.metaTitle_de} />
                                      )}
                                  </div>
                                  <div className="my-2">
                                    <span className="drawer_span ">
                                      Meta Keyword{" "}
                                      <span className="form-label-secondary">(German)</span>
                                    </span>
                                    <input
                                      disabled={view}
                                      type="text"
                                      name="metaKeyword_de"
                                      className={`form-control ${
                                        formik.errors.metaKeyword_de &&
                                        formik.touched.metaKeyword_de
                                          ? "input_error"
                                          : ""
                                      }`}
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                      value={formik.values.metaKeyword_de}
                                      placeholder="Enter Meta Keyword in (German)"
                                    />
                                    {formik.errors.metaKeyword_de &&
                                      formik.touched.metaKeyword_de && (
                                        <ErrorMessage message={formik.errors.metaKeyword_de} />
                                      )}
                                  </div>
                                  <div className="my-3">
                                    <span className="drawer_span">
                                      Meta Description{" "}
                                      <span className="form-label-secondary">(German)</span>
                                    </span>
                                    <RichEditor
                                      value={formik.values.metaDescription_de}
                                      formik={formik}
                                      setValue={(value) =>
                                        formik.setFieldValue("metaDescription_de", value)
                                      }
                                    />
                                  </div>
                                  <div className="my-3">
                                    <span className="drawer_span ">
                                      Overview{" "}
                                      <span className="form-label-secondary">(German)</span>
                                    </span>
                
                                    <RichEditor
                                      value={formik.values.overview_de}
                                      formik={formik}
                                      setValue={(value) =>
                                        formik.setFieldValue("overview_de", value)
                                      }
                                    />
                                    {formik.errors.overview_de &&
                                      formik.touched.overview_de && (
                                        <ErrorMessage message={formik.errors.overview_de} />
                                      )}
                                  </div>
                                </>
                              )}
                
                              {/* Detail  */}
                              {language === "ru" && (
                                <>
                                  {" "}
                                  <div className="my-2">
                                    <span className="drawer_span">
                                      Meta Title{" "}
                                      <span className="form-label-secondary">(Russian)</span>
                                    </span>
                                    <input
                                      disabled={view}
                                      type="text"
                                      name="metaTitle_ru"
                                      className={`form-control ${
                                        formik.errors.metaTitle_ru &&
                                        formik.touched.metaTitle_ru
                                          ? "input_error"
                                          : ""
                                      }`}
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                      value={formik.values.metaTitle_ru}
                                      placeholder="Enter Meta Title in (Russian)"
                                    />
                                    {formik.errors.metaTitle_ru &&
                                      formik.touched.metaTitle_ru && (
                                        <ErrorMessage message={formik.errors.metaTitle_ru} />
                                      )}
                                  </div>
                                  <div className="my-2">
                                    <span className="drawer_span ">
                                      Meta Keyword{" "}
                                      <span className="form-label-secondary">(Russian)</span>
                                    </span>
                                    <input
                                      disabled={view}
                                      type="text"
                                      name="metaKeyword_ru"
                                      className={`form-control ${
                                        formik.errors.metaKeyword_ru &&
                                        formik.touched.metaKeyword_ru
                                          ? "input_error"
                                          : ""
                                      }`}
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                      value={formik.values.metaKeyword_ru}
                                      placeholder="Enter Meta Keyword in (Russian)"
                                    />
                                    {formik.errors.metaKeyword_eru &&
                                      formik.touched.metaKeyword_ru && (
                                        <ErrorMessage message={formik.errors.metaKeyword_ru} />
                                      )}
                                  </div>
                                  <div className="my-3">
                                    <span className="drawer_span">
                                      Meta Description{" "}
                                      <span className="form-label-secondary">(Russian)</span>
                                    </span>
                                    <RichEditor
                                      value={formik.values.metaDescription_ru}
                                      formik={formik}
                                      setValue={(value) =>
                                        formik.setFieldValue("metaDescription_ru", value)
                                      }
                                    />
                                  </div>
                                  <div className="my-3">
                                    <span className="drawer_span ">
                                      Overview{" "}
                                      <span className="form-label-secondary">(Russian)</span>
                                    </span>
                
                                    <RichEditor
                                      value={formik.values.overview_ru}
                                      formik={formik}
                                      setValue={(value) =>
                                        formik.setFieldValue("overview_ru", value)
                                      }
                                    />
                                    {formik.errors.overview_ru &&
                                      formik.touched.overview_ru && (
                                        <ErrorMessage message={formik.errors.overview_ru} />
                                      )}
                                  </div>
                                </>
                              )}
                
                              {/* Detail  */}
                              {language === "uk" && (
                                <>
                                  {" "}
                                  <div className="my-2">
                                    <span className="drawer_span">
                                      Meta Title{" "}
                                      <span className="form-label-secondary">(Ukrainian)</span>
                                    </span>
                                    <input
                                      disabled={view}
                                      type="text"
                                      name="metaTitle_uk"
                                      className={`form-control ${
                                        formik.errors.metaTitle_uk &&
                                        formik.touched.metaTitle_uk
                                          ? "input_error"
                                          : ""
                                      }`}
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                      value={formik.values.metaTitle_uk}
                                      placeholder="Enter Meta Title in (Ukrainian)"
                                    />
                                    {formik.errors.metaTitle_uk &&
                                      formik.touched.metaTitle_uk && (
                                        <ErrorMessage message={formik.errors.metaTitle_uk} />
                                      )}
                                  </div>
                                  <div className="my-2">
                                    <span className="drawer_span ">
                                      Meta Keyword{" "}
                                      <span className="form-label-secondary">(Ukrainian)</span>
                                    </span>
                                    <input
                                      disabled={view}
                                      type="text"
                                      name="metaKeyword_uk"
                                      className={`form-control ${
                                        formik.errors.metaKeyword_uk &&
                                        formik.touched.metaKeyword_uk
                                          ? "input_error"
                                          : ""
                                      }`}
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                      value={formik.values.metaKeyword_uk}
                                      placeholder="Enter Meta Keyword in (Ukrainian)"
                                    />
                                    {formik.errors.metaKeyword_uk &&
                                      formik.touched.metaKeyword_uk && (
                                        <ErrorMessage message={formik.errors.metaKeyword_uk} />
                                      )}
                                  </div>
                                  <div className="my-3">
                                    <span className="drawer_span">
                                      Meta Description{" "}
                                      <span className="form-label-secondary">(Ukrainian)</span>
                                    </span>
                                    <RichEditor
                                      value={formik.values.metaDescription_uk}
                                      formik={formik}
                                      setValue={(value) =>
                                        formik.setFieldValue("metaDescription_uk", value)
                                      }
                                    />
                                  </div>
                                  <div className="my-3">
                                    <span className="drawer_span ">
                                      Overview{" "}
                                      <span className="form-label-secondary">(Ukrainian)</span>
                                    </span>
                
                                    <RichEditor
                                      value={formik.values.overview_uk}
                                      formik={formik}
                                      setValue={(value) =>
                                        formik.setFieldValue("overview_uk", value)
                                      }
                                    />
                                    {formik.errors.overview_uk &&
                                      formik.touched.overview_uk && (
                                        <ErrorMessage message={formik.errors.overview_uk} />
                                      )}
                                  </div>
                                </>
                              )}
              </form>
            </div>
          </div>
        </div>
      </Drawer>.
      <SaveConfirmationDialog
        loading={loading?.remove}
        open={open}
        onClose={setOpen}
        onSave={formik.handleSubmit}
        title="Save"
        content="Are you sure you want to Save this?"
        onCancelLabel="Cancel"
        onConfirmLabel="Save"
      />
    </>
  );
}

export default ContentGlobalDrawer;
